var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import store, { loadLocationBySlug, loadLocationActivity, setMetaInfo } from '@/store';
import locationMenu from './location-menu.vue';
import activityListItem from './activity-list-item.vue';
import PageComponent from '@/lib/page-component';
import * as seo from '@/router/seo';
export default PageComponent.extend({
    name: 'location-activity',
    components: { locationMenu: locationMenu, activityListItem: activityListItem },
    data: function () {
        return {
            location: null,
            locationId: null,
            activity: {
                id: 0,
                title: '',
                type: '',
                subPages: [],
                listings: [],
            },
        };
    },
    methods: {
        updateHeader: function () {
            if (!this.location || !this.activity) {
                return;
            }
            this.setTitle('Activities');
            this.setBackgroundLink('');
            this.setBreadcrumbs([
                { label: 'Home', route: { name: 'home' } },
                { label: 'Activities' },
                { label: this.location.title },
                { label: this.activity.title },
            ]);
            var activity = this.activity;
            var meta_title = activity ? activity.meta_title || '' : '';
            var meta_description = activity ? activity.meta_description || '' : '';
            var meta_keywords = activity ? activity.meta_keywords || '' : '';
            var metaInfoData = {
                title: meta_title,
                meta: [{
                        vmid: 'description',
                        name: 'description',
                        content: meta_description
                    }, {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: meta_keywords
                    }],
                script: seo.getLdJson(+this.location.id)
            };
            setMetaInfo(store, metaInfoData);
        },
    },
    watch: {
        'activity': 'updateHeader'
    },
    beforeRouteUpdate: function (to, from, next) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.activity = null;
                        return [4 /*yield*/, routeUpdate(to, from, function (callback) {
                                callback(_this);
                                next();
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
    beforeRouteEnter: function (to, from, next) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, routeUpdate(to, from, next)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
});
function routeUpdate(to, from, next) {
    return __awaiter(this, void 0, Promise, function () {
        var location, id, _i, _a, activity_1, redirectTo, preference, _loop_1, _b, preference_1, pp, state_1, activity, activityPage;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, loadLocationBySlug(store, { slug: to.params.location })];
                case 1:
                    location = _c.sent();
                    id = -1;
                    for (_i = 0, _a = location.subPages; _i < _a.length; _i++) {
                        activity_1 = _a[_i];
                        if (activity_1.slug.endsWith(to.params.activity)) {
                            id = activity_1.id;
                        }
                    }
                    redirectTo = undefined;
                    if (id === -1) {
                        preference = [36, 13, 12];
                        _loop_1 = function (pp) {
                            var pg = location.subPages.find(function (x) { return x.id === pp; });
                            if (pg) {
                                id = pg.id;
                                redirectTo = pg.slug;
                                return "break";
                            }
                        };
                        for (_b = 0, preference_1 = preference; _b < preference_1.length; _b++) {
                            pp = preference_1[_b];
                            state_1 = _loop_1(pp);
                            if (state_1 === "break")
                                break;
                        }
                        // none of the above? take first
                        if (id === -1) {
                            id = location.subPages[0].id;
                            redirectTo = location.subPages[0].slug;
                        }
                    }
                    return [4 /*yield*/, loadLocationActivity(store, { locationId: location.id, id: id })];
                case 2:
                    activity = _c.sent();
                    activityPage = to.params.slug
                        ? activity.subPages.find(function (x) { return x.slug == "/activities/".concat(to.params.location, "/").concat(to.params.activity, "/").concat(to.params.slug); })
                        : activity;
                    next(function (vm) {
                        vm.$data.location = location;
                        vm.$data.activity = activityPage;
                        if (redirectTo) {
                            vm.$router.push(redirectTo);
                        }
                    });
                    return [2 /*return*/];
            }
        });
    });
}
