import Vue from 'vue';
import propertyThumbnailImage from '@/components/listing/property-thumbnail.vue';
import store, { startLoadingProperty } from '@/store';
import lazyImage from '@/components/lazy-image.vue';
export default Vue.extend({
    name: 'featured-properties',
    components: { propertyThumbnailImage: propertyThumbnailImage, lazyImage: lazyImage },
    props: ['items'],
    methods: {
        startLoading: function (item) {
            startLoadingProperty(store, {
                id: item.id,
                title: item.title,
                url: item.mainImage(),
                isLoadingListing: true
            });
        }
    }
});
