var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import searchBar from '@/components/search-bar.vue';
import properties from '@/components/search/properties.vue';
import * as seo from '@/router/seo';
import store, { loadDiscount, loadDiscounts, loadLocations, setMetaInfo } from '@/store';
import PageComponent from '@/lib/page-component';
export default PageComponent.extend({
    name: 'discount',
    props: ['id'],
    components: { searchBar: searchBar, properties: properties },
    data: function () {
        return {
            data: {
                id: 0,
                category: '',
                price: '',
                short_title: '',
                short_body: '',
                meta_title: '',
                url: '',
                discount_code: '',
                meta_keywords: '',
                meta_description: ''
            },
            expandedFields: {},
            allLocations: [],
        };
    },
    mounted: function () {
        this.updateHeader();
    },
    methods: {
        updateHeader: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, metaInfoData;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.setTitle("Discounts");
                            this.setBackgroundLink('');
                            this.setBreadcrumbs([
                                { label: 'Home', route: { name: 'home' } },
                                { label: 'Discounts', route: { name: 'discounts' } }
                            ]);
                            _a = this;
                            return [4 /*yield*/, loadLocations(store)];
                        case 1:
                            _a.allLocations = (_b.sent()).subPages;
                            metaInfoData = {
                                title: this.data.meta_title,
                                meta: [{
                                        vmid: 'description',
                                        name: 'description',
                                        content: this.data.meta_description
                                    }, {
                                        vmid: 'keywords',
                                        name: 'keywords',
                                        content: this.data.meta_keywords
                                    }],
                                script: seo.getLdJson(+this.data.location)
                            };
                            setMetaInfo(store, metaInfoData);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getLocationList: function () {
            if (!this.data.listings)
                return [];
            var ls = new Set();
            this.data.listings.map(function (x) { return ls.add(x.locationid); });
            var result = this.allLocations.filter(function (x) { return ls.has(x.id); });
            return result;
        },
        getLocationListings: function (lid) {
            return this.data.listings.filter(function (x) { return x.locationid == lid; });
        },
        scrollTo: function (element_id) {
            var target = document.getElementById(element_id);
            var pageY = -30 - target.clientHeight; // nagodenko!
            while (target != null) {
                if (target.offsetTop)
                    pageY += target.offsetTop;
                // @ts-ignore
                target = target.offsetParent;
            }
            (document.scrollingElement || document.documentElement).scrollTo({
                top: pageY,
                left: 0,
                behavior: 'smooth'
            });
        }
    },
    beforeRouteEnter: function (to, from, next) {
        return __awaiter(this, void 0, void 0, function () {
            var id, allDiscounts, thisDiscount, discount, e_1, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        id = parseInt(to.params.id, 10);
                        return [4 /*yield*/, loadDiscounts(store)];
                    case 1:
                        allDiscounts = _a.sent();
                        thisDiscount = allDiscounts.discounts.find(function (x) { return x.id === id; });
                        discount = null;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, loadDiscount(store, id)];
                    case 3:
                        discount = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5:
                        result = thisDiscount ? discount : null;
                        if (result)
                            next(function (vm) { return vm.$data.data = result; });
                        else
                            next({ path: '/404' });
                        return [2 /*return*/];
                }
            });
        });
    },
});
