import Vue from 'vue';
var LOADER_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==';
var DEFAULT_MARGIN = '50px 50px';
var DEFAULT_THRESHOLD = 0.1;
export function getIntersectionObserver(callback, props) {
    return new IntersectionObserver(callback, props);
}
export default Vue.extend({
    name: 'LazyImage',
    props: ['url', 'alt', 'placeholderImageUrl', 'width', 'height'],
    data: function () {
        var result = {
            observer: null,
            loaded: false,
        };
        return result;
    },
    computed: {
        liveUrl: function () {
            return this.loaded ? this.url : this.placeholderImageUrl || LOADER_IMAGE;
        }
    },
    mounted: function () {
        var _this = this;
        this.observer = getIntersectionObserver(function (entries) {
            var image = entries[0];
            if (image.isIntersecting) {
                _this.loaded = true;
                _this.observer.disconnect();
                delete _this.observer;
            }
        }, {
            rootMargin: DEFAULT_MARGIN,
            threshold: DEFAULT_THRESHOLD
        });
        this.observer.observe(this.$el);
    },
    destroyed: function () {
        if (this.observer) {
            this.observer.disconnect();
        }
    }
});
