var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import api from '@/api';
export function dateParser(date) {
    if (!date) {
        return null;
    }
    if (typeof date == "string") {
        var parts = date.split("/");
        // MM/DD/YYYY
        var dt = new Date(parseInt(parts[2], 10), parseInt(parts[0], 10) - 1, parseInt(parts[1], 10));
        return dt;
    }
    else {
        if (Array.isArray(date) && date.length >= 1) {
            return dateParser(date[0]);
        }
        else {
            return dt;
        }
    }
}
export function dateFormatter(date) {
    if (!date) {
        return '';
    }
    if (typeof date === 'string') {
        date = new Date(date);
    }
    var d = date;
    var value = "".concat((d.getMonth() + 1), "/").concat(d.getDate(), "/").concat(d.getFullYear());
    return value;
}
export function parseNumber(value) {
    if (typeof value == "string") {
        return parseInt(value, 10);
    }
    if (Array.isArray(value)) {
        return parseNumber(value[0]);
    }
    return parseNumber(value.toString());
}
export function getCookie(name) {
    var value = "; ".concat(document.cookie);
    var parts = value.split("; ".concat(name, "="));
    if (parts.length === 2)
        return parts.pop().split(';').shift();
}
export function generateFBData(name) {
    var fbData = {
        data: [
            {
                event_name: name,
                event_time: Math.floor(Date.now() / 1000),
                user_data: {
                    client_user_agent: navigator.userAgent,
                },
                event_source_url: window.location.href,
                action_source: 'website'
            }
        ]
    };
    return fbData;
}
export function sendFBData(eventType, fbc) {
    return __awaiter(this, void 0, void 0, function () {
        var fbData, fbp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    fbData = generateFBData(eventType);
                    //@ts-ignore
                    if (fbc)
                        fbData.data[0].user_data.fbc = fbc;
                    fbp = getCookie("_fbp");
                    if (!fbp) return [3 /*break*/, 2];
                    fbData.data[0].user_data.fbp = fbp;
                    return [4 /*yield*/, api.facebookEvent(fbData)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
}
