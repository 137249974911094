import Vue from 'vue';
export default Vue.extend({
    name: 'location-menu',
    props: ['items'],
    data: function () {
        return {
            showTitles: false,
            title: '',
        };
    },
    methods: {
        itemClass: function (item) {
            if (this.$route.path === item.slug) {
                this.title = item.title;
                return 'active';
            }
            return '';
        },
        itemIcon: function (item) {
            return 'fa-' + item.picture;
        }
    }
});
