var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import discounts from './discounts.vue';
import featuredProperties from './featured-properties/list.vue';
import testimonials from './testimonials.vue';
import videos from './videos.vue';
import * as seo from '@/router/seo';
import store, { loadDiscounts, loadHomepage, loadHomepageListings, setMetaInfo } from '@/store';
import footnotes from '@/components/footnotes.vue';
export default Vue.extend({
    name: 'root',
    components: { discounts: discounts, featuredProperties: featuredProperties, testimonials: testimonials, videos: videos, footnotes: footnotes },
    data: function () {
        return {
            data: {
                title: '',
                body: '',
                meta_title: '',
                meta_keywords: '',
                discounts: [],
                testimonials: [],
                featured_properties: [],
                videos: [],
                slider: [],
                showDeferred: false,
                text_section_1: '',
                text_section_2: '',
            },
        };
    },
    mounted: function () {
        return __awaiter(this, void 0, void 0, function () {
            var self;
            return __generator(this, function (_a) {
                self = this;
                setTimeout(function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var homepage, metaInfoData;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, loadHomepageListings(store)];
                                case 1:
                                    homepage = _a.sent();
                                    self.$data.data.featured_properties = homepage.featured_properties;
                                    self.$data.data.showDeferred = true;
                                    metaInfoData = {
                                        title: self.$data.data.meta_title,
                                        meta: [{
                                                vmid: 'description',
                                                name: 'description',
                                                content: self.$data.data.meta_description
                                            }, {
                                                vmid: 'keywords',
                                                name: 'keywords',
                                                content: self.$data.data.meta_keywords
                                            }],
                                        script: seo.getLdJson(seo.getDefaultPageLocation())
                                    };
                                    setMetaInfo(store, metaInfoData);
                                    return [2 /*return*/];
                            }
                        });
                    });
                }, 100);
                return [2 /*return*/];
            });
        });
    },
    beforeRouteEnter: function (to, from, next) {
        return __awaiter(this, void 0, void 0, function () {
            var homepage, discounts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, loadHomepage(store)];
                    case 1:
                        homepage = _a.sent();
                        return [4 /*yield*/, loadDiscounts(store)];
                    case 2:
                        discounts = _a.sent();
                        next(function (vm) {
                            // vm.$data.data = homepage
                            vm.$data.data.title = homepage.title;
                            vm.$data.data.body = homepage.body;
                            vm.$data.data.meta_title = homepage.meta_title;
                            vm.$data.data.meta_description = homepage.meta_description;
                            vm.$data.data.meta_keywords = homepage.meta_keywords;
                            vm.$data.data.discounts = discounts.discounts;
                            vm.$data.data.testimonials = homepage.testimonials;
                            vm.$data.data.featured_properties = homepage.featured_properties;
                            vm.$data.data.videos = homepage.videos;
                            vm.$data.data.text_section_1 = homepage.text_section_1;
                            vm.$data.data.text_section_2 = homepage.text_section_2;
                        });
                        return [2 /*return*/];
                }
            });
        });
    },
});
