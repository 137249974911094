var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import api from "@/api";
import * as quoteLib from '@/lib/quote';
function emptyApplicantData(aJob, all_locations, all_departments) {
    return {
        job: aJob,
        locationName: all_locations.find(function (x) { return x.id == aJob.locationID; }).text,
        departmentName: all_departments.find(function (x) { return x.id == aJob.departmentID; }).text,
        Name: '',
        Email: '',
        Phone: '',
        files: new FormData(),
        recaptcha: '',
        RequestCopy: true
    };
}
export default Vue.extend({
    name: 'applyNow',
    props: ['job', 'job_departments', 'all_locations'],
    data: function () {
        return {
            applicantData: emptyApplicantData(this.job, this.all_locations, this.job_departments),
            validation: {},
            errors: [],
            errorMessage: '',
            SubmitBtnText: 'Apply Now',
            CanSubmit: true,
            modalOpened: false,
            isResumeSelected: false
        };
    },
    mounted: function () {
        var modal = this.$refs.modalContainer;
        document.body.insertBefore(modal, document.body.firstChild);
    },
    watch: {
        "modalOpened": "disableScroll"
    },
    methods: {
        disableScroll: function () {
            document.body.style.overflow = this.modalOpened ? "hidden" : "";
        },
        resumeFileChange: function (fileList) {
            for (var _i = 0, fileList_1 = fileList; _i < fileList_1.length; _i++) {
                var f = fileList_1[_i];
                this.applicantData.files.set('resume', f, f.name);
                // this.applicantData.resumeFiles = new FormData()
                // this.applicantData.resumeFiles.append('file', f, f.name)
            }
            this.isResumeSelected = true;
        },
        coverLetterFileChange: function (fileList) {
            for (var _i = 0, fileList_2 = fileList; _i < fileList_2.length; _i++) {
                var f = fileList_2[_i];
                this.applicantData.files.set('coverLetter', f, f.name);
                // this.applicantData.coverLetterFiles = new FormData()
                // this.applicantData.coverLetterFiles.append('file', f, f.name)
            }
        },
        photoFileChange: function (fileList) {
            for (var _i = 0, fileList_3 = fileList; _i < fileList_3.length; _i++) {
                var f = fileList_3[_i];
                this.applicantData.files.set('photo', f, f.name);
                // this.applicantData.photoFiles = new FormData()
                // this.applicantData.photoFiles.append('file', f, f.name)
            }
        },
        onComplete: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (this.validate(true)) {
                        // @ts-ignore
                        this.$refs.invisibleRecaptchaApplyNow.execute();
                    }
                    return [2 /*return*/];
                });
            });
        },
        recaptchaCallback: function (recaptchaToken) {
            return __awaiter(this, void 0, void 0, function () {
                var response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.applicantData.recaptcha = recaptchaToken;
                            return [4 /*yield*/, api.applyForJob(this.applicantData)];
                        case 1:
                            response = _a.sent();
                            this.SubmitBtnText = "Apply Now";
                            this.CanSubmit = true;
                            if (!response.error) {
                                this.modalOpened = false;
                                this.applicantData = emptyApplicantData(this.job, this.all_locations, this.job_departments);
                                this.isResumeSelected = false;
                                this.errors = [];
                                this.validation = {};
                                this.$refs.wizard.reset();
                                this.applicantData.Name = '';
                                this.applicantData.Email = '';
                                this.applicantData.Phone = '';
                                this.applicantData.RequestCopy = true;
                                alert("Thank you for applying for this job. We will contact you shortly.");
                            }
                            else {
                                this.errorMessage = response.error;
                            }
                            return [2 /*return*/];
                    }
                });
            });
        },
        validate: function (p) {
            var v_result = quoteLib.ValidateFormFields(this.$refs);
            v_result.validationErrors["Resume"] = !this.isResumeSelected;
            this.validation = v_result.validationErrors;
            var result = v_result.errors.length == 0;
            if (result && p) {
                this.$data.SubmitBtnText = "Please wait...";
                this.$data.CanSubmit = false;
            }
            return result;
        },
        setRequestCopy: function () {
            this.applicantData.RequestCopy = !this.applicantData.RequestCopy;
        },
    }
});
