import store from '@/store';
import { parseNumber } from './tools';
export function datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second.valueOf() - first.valueOf()) / (1000 * 60 * 60 * 24));
}
export function addDays(date, days) {
    var dat = new Date(date.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
}
export function minDate(date1, date2) {
    if (date1.getTime() < date2.getTime()) {
        return date1;
    }
    else {
        return date2;
    }
}
export function maxDate(date1, date2) {
    if (date1.getTime() > date2.getTime()) {
        return date1;
    }
    else {
        return date2;
    }
}
export function ValidateFormFields($refs) {
    var result = {
        errors: [],
        validationErrors: {}
    };
    for (var ctl_name in $refs) {
        var inp = $refs[ctl_name];
        if (inp == null) {
            continue;
        }
        if (inp.value == null)
            continue;
        var isEmpty = inp.value.trim() == '';
        if (inp.options && inp.value == '0')
            isEmpty = true;
        result.validationErrors[ctl_name] = isEmpty;
        if (isEmpty) {
            if (result.errors.indexOf(inp.attributes['placeholder'].value) == -1)
                result.errors.push(inp.attributes['placeholder'].value);
        }
    }
    return result;
}
export function IsAvailable(Listing, Arrive, Depart) {
    var availability = Listing.availability;
    var firstDate = Listing.availabilityFirstDate;
    if (availability == null) {
        return false;
    }
    if (!Arrive) {
        return true;
    }
    var depart = Depart || addDays(Arrive, 1);
    var n1 = datediff(firstDate, Arrive);
    var n2 = datediff(firstDate, depart) - 1;
    if (n1 < 0 || n2 > availability.length) {
        return false;
    }
    for (var i = n1; i <= n2; i++) {
        if (!availability[i]) {
            return false;
        }
    }
    return true;
}
export function GetOccupiedDays(Listing, Arrive, Depart) {
    var availability = Listing.availability;
    var firstDate = Listing.availabilityFirstDate;
    if (availability == null) {
        return -1;
    }
    if (!Arrive) {
        return -1;
    }
    var depart = Depart || addDays(Arrive, 1);
    var n1 = datediff(firstDate, Arrive);
    var n2 = datediff(firstDate, depart) - 1;
    if (n1 < 0 || n2 > availability.length) {
        return -1;
    }
    var occupiedDays = 0;
    for (var i = n1; i <= n2; i++) {
        if (!availability[i]) {
            occupiedDays++;
        }
    }
    return occupiedDays;
}
export function IsLastMinuteDiscountAppliable(discount, Listing, arriveTime, departTime) {
    if (!discount.isLastMinute || !discount.percent) {
        return false;
    }
    var today = new Date(new Date().setHours(0, 0, 0, 0));
    var discountEndDate = new Date(new Date().setHours(0, 0, 0, 0));
    discountEndDate.setDate(today.getDate() + discount.numDays);
    var discountEndAsTime = discountEndDate.getTime();
    var maxOccupiedDays = GetOccupiedDays(Listing, today, discountEndDate);
    return arriveTime <= discountEndAsTime && departTime <= discountEndAsTime &&
        maxOccupiedDays !== -1 && maxOccupiedDays <= discount.maxOccupiedDays;
}
export function getMinStay(Listing, Arrive, Depart) {
    if (!Arrive) {
        Arrive = addDays(new Date(new Date().toDateString()), 1);
    }
    if (!Depart) {
        Depart = addDays(Arrive, 7);
    }
    var result = 1;
    (Listing.rates || []).map(function (r) {
        if (r.minimumNights > result && new Date(r.startOn) <= Depart && new Date(r.endOn) >= Arrive)
            result = r.minimumNights;
    });
    if (result == 2)
        result = 1;
    if (result == 4)
        result = 3;
    if (result > 5)
        result = 5;
    return result;
}
export function CalcPropertyPriceQuote(Listing, Arrive, Depart, NumPets, SelectedDiscount, InsuranceCoverage) {
    var result = {
        errors: [],
        quotePrice: 0,
        quoteTotalLodging: 0,
        quoteTotalTax: 0,
        quoteTotalFees: 0,
        quoteCleaningFee: 0,
        quoteResFee: 0,
        quotePetFee: 0,
        quoteOtherFees: 0,
        quoteTotalInsurance: 0,
        quoteTotalRefUpgrade: 0,
        travelInsuranceCostPercentage: 0
    };
    if (!Arrive || !Depart) {
        result.errors.push('Please select a period');
        return result;
    }
    var m_isAvailable = IsAvailable(Listing, Arrive, Depart);
    if (!m_isAvailable) {
        result.errors.push('The dates you have requested are no longer available');
        return result;
    }
    var numDays = datediff(Arrive, Depart);
    var dailyRates = [];
    for (var i = 0; i < numDays; i++) {
        dailyRates[i] = 0;
    }
    for (var _i = 0, _a = Listing.rates; _i < _a.length; _i++) {
        var rr = _a[_i];
        var startingNdx = datediff(Arrive, new Date(rr.startOn));
        var endingNdx = datediff(Arrive, new Date(rr.endOn));
        if (startingNdx < 0)
            startingNdx = 0;
        if (endingNdx >= numDays)
            endingNdx = numDays - 1;
        var d = addDays(Arrive, startingNdx);
        for (var i = startingNdx; i <= endingNdx; i++) {
            dailyRates[i] = d.getDay() == 5 || d.getDay() == 6 ? rr.weekendRate : rr.weekdayRate;
            // minStay[i] = rr.MinimumNights
            d = addDays(d, 1);
        }
    }
    Listing.rates = Listing.rates || [];
    Listing.fees = Listing.fees || [];
    var lodgingTax = Listing.lodgingTax;
    var serviceTax = Listing.serviceTax;
    var resFee = Listing.fees.filter(function (x) { return x.code == 'RESFEE'; }).reduce(function (a, v) { return a + v.amount; }, 0);
    var totalLodging = dailyRates.reduce(function (a, v) { return a + v; }, 0);
    var totalFees = Listing.fees
        .filter(function (x) { return !x.isPercentage && x.code != 'PETFEE' && x.code != 'ADDGST'; })
        .reduce(function (a, v) { return a + v.amount; }, 0);
    var cleaningFee = Listing.fees
        .filter(function (x) { return x.code == 'DEPCLN'; })
        .reduce(function (a, v) { return a + v.amount; }, 0);
    var petFees = Listing.fees
        .filter(function (x) { return x.code == 'PETFEE'; })
        .reduce(function (a, v) { return a + v.amount * NumPets; }, 0);
    totalFees += petFees;
    var quoteDiscount = 0;
    if (SelectedDiscount && SelectedDiscount.code != null && SelectedDiscount.code != '') {
        if (SelectedDiscount.isPercentage) {
            // check how many dates are applicable and how many are blackouted
            quoteDiscount =
                (SelectedDiscount.applicableDays
                    .map(function (d) {
                    var n = datediff(Arrive, new Date(d));
                    return dailyRates[n];
                })
                    .reduce(function (a, v) { return a + v; }, 0) *
                    parseNumber(SelectedDiscount.amount)) /
                    100;
            // if (Listing.id == 265) {
            //   console.log('SelectedDiscount', SelectedDiscount);
            //   console.log('SelectedDiscount.applicableDays', SelectedDiscount.applicableDays);
            //   console.log('dailyRates', dailyRates);
            //   console.log('quoteDiscount', quoteDiscount);
            // }
        }
        else {
            quoteDiscount = parseNumber(SelectedDiscount.amount);
        }
    }
    var totalIns = Listing.damageInsurance;
    var travelInsuranceCostPercentage = 0;
    var refUpgradePercent = 0;
    if (InsuranceCoverage === 'ti') {
        travelInsuranceCostPercentage = 7;
    }
    else if (InsuranceCoverage === 'cfar') {
        travelInsuranceCostPercentage = 9.5;
    }
    else if (InsuranceCoverage === 'rfbk') {
        refUpgradePercent = 10;
    }
    var calculationMethod = Listing.resFeeCalcID || 0;
    if (store.state.isTestMode) {
        calculationMethod = 1;
    }
    var totalTax = Math.round((totalLodging - quoteDiscount) * lodgingTax + (totalFees + totalIns) * serviceTax) / 100.0;
    var resSubTotal = totalLodging + totalFees + (calculationMethod === 0 ? totalTax : 0) + totalIns - quoteDiscount;
    var totalResFee = Math.round(resSubTotal * resFee) / 100.0;
    result.resSubTotal = resSubTotal;
    totalTax += Math.round(totalResFee * serviceTax) / 100.0;
    totalFees += totalResFee;
    var quotePrice = Math.round((totalLodging + totalFees + totalTax + totalIns - quoteDiscount) * 100) / 100;
    var travelInsuranceCost = (quotePrice * travelInsuranceCostPercentage) / 100;
    quotePrice += travelInsuranceCost;
    var quoteTotalRefUpgradePrice = (quotePrice * refUpgradePercent) / 100;
    var quotePriceWithoutRefUpgrade = quotePrice;
    quotePrice += quoteTotalRefUpgradePrice;
    totalTax += (serviceTax / 100.0 * quoteTotalRefUpgradePrice);
    quotePrice += (serviceTax / 100.0 * quoteTotalRefUpgradePrice);
    totalIns += travelInsuranceCost; //maybie not needed
    result.quotePrice = quotePrice;
    result.quoteDiscount = quoteDiscount;
    result.quoteTotalLodging = totalLodging;
    result.quoteTotalTax = totalTax;
    result.quoteTotalFees = totalFees;
    result.quoteTotalInsurance = totalIns;
    result.quoteTotalRefUpgrade = quoteTotalRefUpgradePrice;
    result.travelInsuranceCostPercentage = travelInsuranceCostPercentage;
    result.quotePriceWithoutRefUpgrade = quotePriceWithoutRefUpgrade;
    result.arrive = Arrive;
    result.depart = Depart;
    result.quoteCleaningFee = cleaningFee;
    result.quoteResFee = totalResFee;
    return result;
}
