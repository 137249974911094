import Vue from 'vue';
export default Vue.extend({
    data: function () {
        return {
            isActiveHome: false,
            isActiveInfo: false,
            isActiveMap: false,
        };
    },
    computed: {
        listingNum: function () {
            return this.$route.params.listingNum;
        },
        pubResId: function () {
            return this.$route.params.pubResId;
        },
    },
    mounted: function () {
        var _a;
        var routeName = (_a = this.$route.name) === null || _a === void 0 ? void 0 : _a.toString();
        if (routeName === null || routeName === void 0 ? void 0 : routeName.includes("home")) {
            this.isActiveHome = true;
        }
        else if (routeName === null || routeName === void 0 ? void 0 : routeName.includes("map")) {
            this.isActiveMap = true;
        }
        else {
            this.isActiveInfo = true;
        }
    }
});
