import Vue from 'vue';
export default Vue.extend({
    name: 'videos',
    props: ['items'],
    data: function () {
        return {
            selectedVideo: ""
        };
    },
    methods: {
        toggle: function (video_id) {
            if (this.selectedVideo == video_id)
                this.selectedVideo = "";
            else
                this.selectedVideo = video_id;
        },
        thumbnail: function (video_id) {
            return "//i.ytimg.com/vi/".concat(video_id, "/hqdefault.jpg");
        },
        iframeURL: function (video_id) {
            return "//www.youtube.com/embed/".concat(video_id, "?autoplay=0&mute=0&autohide=2&border=0&wmode=opaque&enablejsapi=1&controls=0&showinfo=0&rel=0");
            // return `//www.youtube.com/embed/${video_id}?autoplay=1&autohide=2&border=0&wmode=opaque&enablejsapi=1&controls=0&showinfo=0&rel=0`
        },
    }
});
