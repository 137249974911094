import Vue from 'vue';
import { siteName } from '@/config';
export default Vue.extend({
    name: 'discounts',
    props: ['items', 'isHomePage'],
    methods: {
        color: function (index) {
            switch (index % 4) {
                case 0: return 'color-2';
                case 1: return 'color-3';
                case 2: return 'color-1';
                case 3: return 'color-4';
                default: return 'color-2';
            }
        },
        getDiscountParallax: function () {
            return 'image_discount_parallax_' + siteName;
        }
    },
});
