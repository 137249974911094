var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import api from "@/api";
import * as quoteLib from '@/lib/quote';
import holidayMessage from './holiday-message.vue';
import availability from './availability.vue';
import insurance from './insurance.vue';
import reservationQuote from './quote.vue';
import preview from './preview.vue';
import renter from './renter.vue';
import payment from './payment.vue';
import { siteName, isTestMode } from '@/config';
import store from '@/store';
import { isMobile } from '@/api';
import { sendFBData } from '@/lib/tools';
function newReservation(aListing, lastSearch) {
    return {
        isHoliday: false,
        prevQuote: {
            Listing: {
                id: 0
            },
            Arrive: new Date(0),
            Depart: new Date(0),
            NumGuests: 0,
            InsuranceCoverage: '',
            SelectedDiscount: { code: '' }
        },
        quote: {
            Listing: aListing,
            Arrive: (lastSearch || { start: undefined }).start,
            Depart: (lastSearch || { end: undefined }).end,
            NumGuests: 2,
            NumPets: 0,
            Adults: 2,
            Children: 0,
            InsuranceCoverage: 'di',
            SelectedDiscount: { code: '', name: '' },
            renter: {
                firstName: isTestMode ? 'Nikolay' : '',
                lastName: isTestMode ? 'Simeonov' : '',
                dateOfBirth: isTestMode ? new Date(1972, 8, 11) : null,
                address: isTestMode ? '3741 Jasmine Ave' : '',
                city: isTestMode ? 'Los Angeles' : '',
                state: isTestMode ? 'CA' : '',
                zip: isTestMode ? '90034' : '',
                phone: isTestMode ? '3109801234' : '',
                altPhone: '',
                email: isTestMode ? 'nsimeonov@gmail.com' : '',
                emailVerification: isTestMode ? 'nsimeonov@gmail.com' : '',
                countryID: 225,
                agentID: 'NONE',
                referrerID: (siteName === 'mrbo' ? '1' : siteName === 'steam' ? '31' : '2'),
                card_number: isTestMode ? '4111111111111111' : '',
                card_CVV: isTestMode ? '111' : '',
                card_expMonth: isTestMode ? '12' : 0,
                card_expYear: isTestMode ? '2025' : 0,
                billing_name: '',
                billing_address: '',
                billing_city: '',
                billing_state: '',
                billing_zip: '',
                billing_countryID: 225,
                agreeToTermsOfRental: false,
                agreeToCancelationPolicy: false,
                agreeToBackgroundCheck: false,
                agreeToRefundableBooking: false,
                subscribeToPromotions: true,
                vehicleMake: '',
                vehicleModel: '',
            }
        },
        quotePrice: { errors: ["Please select period"] },
        quotePriceQuick: { errors: ["Please select period"] },
        errors: [],
        modalOpened: false,
        TicketsPopupVisible: false
    };
}
export default Vue.extend({
    name: 'reservation',
    props: ['listing', 'lastSearch'],
    components: { availability: availability, insurance: insurance, preview: preview, reservationQuote: reservationQuote, renter: renter, payment: payment, holidayMessage: holidayMessage },
    data: function () {
        var result = newReservation(this.listing, this.lastSearch);
        if (result.quote.Arrive != undefined && result.quote.Depart != undefined) {
            result.quotePrice = quoteLib.CalcPropertyPriceQuote(result.quote.Listing, result.quote.Arrive, result.quote.Depart, result.quote.NumPets, result.quote.SelectedDiscount, result.quote.InsuranceCoverage);
        }
        result.quotePriceQuick = quoteLib.CalcPropertyPriceQuote(result.quote.Listing, result.quote.Arrive, result.quote.Depart, result.quote.NumPets, result.quote.SelectedDiscount, result.quote.InsuranceCoverage);
        return result;
    },
    computed: {
        isTestMode: function () {
            return store.state.isTestMode;
        }
    },
    mounted: function () {
        var modal = this.$refs.modalContainer;
        document.body.insertBefore(modal, document.body.firstChild);
    },
    updated: function () {
        var _this = this;
        if (this.quote.Listing.id != this.listing.id) {
            var result = newReservation(this.listing, this.lastSearch);
            this.quote = result.quote;
            this.quotePrice = result.quotePrice;
            this.errors = result.errors;
            // @ts-ignore
            this.$refs.wizard.navigateToTab(0);
        }
        if (this.quote.Arrive && this.quote.Depart) {
            var arrive_1 = new Date(this.quote.Arrive.setHours(0, 0, 0, 0));
            var depart_1 = new Date(this.quote.Depart.setHours(0, 0, 0, 0));
            this.isHoliday = this.quote.Listing.blackouts.some(function (b) {
                return depart_1.getTime() >= new Date(b.from_date).getTime() &&
                    arrive_1.getTime() <= new Date(b.to_date).getTime() &&
                    (_this.quote.Listing.locationid == b.locationId || b.locationId == 0);
            });
        }
    },
    watch: {
        "modalOpened": "disableScroll"
    },
    methods: {
        disableScroll: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            document.body.style.overflow = this.modalOpened ? "hidden" : "";
                            document.getElementById('refundable-container-wrapper').style.display = 'none';
                            if (!this.modalOpened) return [3 /*break*/, 2];
                            //@ts-ignore
                            return [4 /*yield*/, sendFBData("Initiate checkout", this.$router.fbc)];
                        case 1:
                            //@ts-ignore
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        },
        onMobile: function () {
            return isMobile();
        },
        onComplete: function () {
            var res = newReservation(this.listing, this.lastSearch);
            this.modalOpened = false;
            this.quote = res.quote;
            this.quotePrice = res.quotePrice;
            this.errors = res.errors;
            this.$refs.wizard.reset();
        },
        onNextTab: function (prevIndex, nextIndex) {
            var step = this.$refs['step' + (nextIndex + 1)];
            if (step && step.onEnter)
                step.onEnter();
        },
        goToTab: function () {
            var $wizard = this.$refs['wizard'];
            $wizard.changeTab($wizard.activeTabIndex, 1);
        },
        showErrors: function (errors_param) {
            this.quotePrice.errors = null;
            this.errors = errors_param;
        },
        updateQuote: function (quote) {
            var _a, _b, _c, _d, _e, _f;
            return __awaiter(this, void 0, void 0, function () {
                var result;
                return __generator(this, function (_g) {
                    switch (_g.label) {
                        case 0:
                            if (((_a = this.prevQuote.Listing) === null || _a === void 0 ? void 0 : _a.id) === ((_b = quote.Listing) === null || _b === void 0 ? void 0 : _b.id) &&
                                this.prevQuote.Arrive === quote.Arrive &&
                                this.prevQuote.Depart === quote.Depart &&
                                this.prevQuote.NumGuests === quote.NumGuests &&
                                this.prevQuote.InsuranceCoverage === quote.InsuranceCoverage &&
                                ((_c = this.prevQuote.SelectedDiscount) === null || _c === void 0 ? void 0 : _c.code) === ((_d = quote.SelectedDiscount) === null || _d === void 0 ? void 0 : _d.code) &&
                                this.prevQuote.NumPets === quote.NumPets)
                                return [2 /*return*/];
                            this.prevQuote.Listing.id = (_e = quote.Listing) === null || _e === void 0 ? void 0 : _e.id;
                            this.prevQuote.Arrive = quote.Arrive;
                            this.prevQuote.Depart = quote.Depart;
                            this.prevQuote.NumGuests = quote.NumGuests;
                            this.prevQuote.InsuranceCoverage === quote.InsuranceCoverage;
                            this.prevQuote.SelectedDiscount.code = (_f = quote.SelectedDiscount) === null || _f === void 0 ? void 0 : _f.code;
                            this.prevQuote.NumPets = quote.NumPets;
                            this.quote = quote;
                            result = JSON.parse(JSON.stringify(this.quotePrice));
                            result.errors = ["Calculating quote"];
                            this.quotePrice = result;
                            return [4 /*yield*/, api.get_quote(quote.Listing.pid, quote.Arrive, quote.Depart, quote.Adults, quote.Children, quote.NumPets, (quote.SelectedDiscount || { code: '' }).code, quote.InsuranceCoverage || 'di')];
                        case 1:
                            // if (quote.SelectedDiscount == null || quote.SelectedDiscount.code == "")
                            //   result = quoteLib.CalcPropertyPriceQuote(quote.Listing, quote.Arrive, quote.Depart, quote.NumPets, quote.SelectedDiscount, quote.InsuranceCoverage)
                            // else
                            result = _g.sent();
                            this.quotePrice = result;
                            this.quotePriceQuick = quoteLib.CalcPropertyPriceQuote(quote.Listing, quote.Arrive, quote.Depart, quote.NumPets, quote.SelectedDiscount, quote.InsuranceCoverage || 'di');
                            return [2 /*return*/];
                    }
                });
            });
        },
        validate: function (ref) {
            return __awaiter(this, void 0, void 0, function () {
                var step, _a, e_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            step = this.$refs[ref];
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 3, , 4]);
                            _a = this;
                            return [4 /*yield*/, step.validate(null, true)];
                        case 2:
                            _a.errors = _b.sent();
                            this.quotePrice.errors = null;
                            return [2 /*return*/, !(this.errors && this.errors.length > 0)];
                        case 3:
                            e_1 = _b.sent();
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        isLastStep: function () {
            if (this.$refs.wizard) {
                return this.$refs.wizard.isLastStep;
            }
            return false;
        },
        isOkToContinueForSameDayRes: function (index) {
            if (index < 2) {
                return true;
            }
            var today = new Date(new Date().setHours(0, 0, 0, 0));
            return (api.formatDate(this.quote.Arrive) !== api.formatDate(today));
        },
        openNewTab: function (url) {
            window.open(url);
        }
    }
});
