var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import store, { loadConciergeServices, setMetaInfo } from '@/store';
import * as types from '@/types';
import api, { getPageImageURL, getSpecificPageImageURL } from '@/api';
import PageComponent from '@/lib/page-component';
import * as seo from '@/router/seo';
export default PageComponent.extend({
    data: function () {
        return {
            conciergeServices: null,
            elevateStay: {
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                destination: '',
                interests: '',
                needToAdd: '',
                validationErrors: {},
            },
            SubmitBtnText: "Send",
            CanSubmit: true
        };
    },
    mounted: function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                setTimeout(function () {
                    _this.updateHeader();
                    window.addEventListener('resize', _this.updateHeader);
                }, 100);
                return [2 /*return*/];
            });
        });
    },
    methods: {
        getTinyImage: function (pageId) {
            return getPageImageURL(pageId, types.ImageSize.tiny);
        },
        getSmallImage: function (pageId) {
            return getPageImageURL(pageId, types.ImageSize.small);
        },
        getMediumImage: function (pageId) {
            return getPageImageURL(pageId, types.ImageSize.medium);
        },
        getLargeImage: function (pageId) {
            return getPageImageURL(pageId, types.ImageSize.large);
        },
        getExtraLargeImage: function (pageId) {
            return getPageImageURL(pageId, types.ImageSize.extra_large);
        },
        setImage: function (pageId) {
            return "background-image: url('".concat(this.getExtraLargeImage(pageId), "')");
        },
        goToForm: function () {
            var element = document.getElementById('elevate-form');
            var headerOffset = 160;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.scrollY - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        },
        validate: function () {
            var _this = this;
            var errors = {};
            errors.firstName = this.$data.elevateStay.firstName.trim() == "";
            errors.lastName = this.$data.elevateStay.lastName.trim() == "";
            errors.email = this.$data.elevateStay.email.trim() == "";
            errors.phone = this.$data.elevateStay.phone.trim() == "";
            errors.destination = this.$data.elevateStay.destination == "";
            errors.interests = this.$data.elevateStay.interests == "";
            this.$data.elevateStay.validationErrors = errors;
            if (errors.firstName || errors.lastName || errors.email || errors.phone || errors.destination || errors.interests)
                return false;
            this.$data.SubmitBtnText = "Please wait...";
            this.$data.CanSubmit = false;
            setTimeout(function () {
                _this.$data.CanSubmit = true;
            }, 5000);
            return true;
        },
        recaptchaCallback: function (recaptchaToken) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.$data.elevateStay.recaptcha = recaptchaToken;
                            this.$data.SubmitBtnText = "Send";
                            this.$data.CanSubmit = true;
                            return [4 /*yield*/, api.elevateStay(this.$data.elevateStay)];
                        case 1:
                            _a.sent();
                            this.$data.elevateStay.firstName = '';
                            this.$data.elevateStay.lastName = '';
                            this.$data.elevateStay.phone = '';
                            this.$data.elevateStay.email = '';
                            this.$data.elevateStay.destination = '';
                            this.$data.elevateStay.interests = '';
                            this.$data.elevateStay.needToAdd = '';
                            alert('Thank you for your submission! We will be in touch shortly.');
                            return [2 /*return*/];
                    }
                });
            });
        },
        OnSubmit: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (this.validate()) {
                        // @ts-ignore
                        this.$refs.invisibleRecaptchaContact.execute();
                    }
                    return [2 /*return*/];
                });
            });
        },
        updateHeader: function () {
            return __awaiter(this, void 0, void 0, function () {
                var width, metaInfoData;
                return __generator(this, function (_a) {
                    this.setTitle('');
                    width = window.innerWidth;
                    if (width > 991 && this.conciergeServices.video) {
                        this.setBackgroundLink("https://www.youtube.com/embed/".concat(this.conciergeServices.video, "?autoplay=1&mute=1&controls=0&rel=0&loop=1&disablekb=1&playlist=").concat(this.conciergeServices.video));
                    }
                    else {
                        this.setBackgroundLink(this.conciergeServices.video ? getSpecificPageImageURL(this.conciergeServices.id, types.ImageSize.extra_large) : "");
                    }
                    this.setBreadcrumbs([]);
                    metaInfoData = {
                        title: this.conciergeServices.meta_title,
                        meta: [{
                                vmid: 'description',
                                name: 'description',
                                content: this.conciergeServices.meta_description
                            }, {
                                vmid: 'keywords',
                                name: 'keywords',
                                content: this.conciergeServices.meta_keywords
                            }],
                        script: seo.getLdJson(seo.getDefaultPageLocation())
                    };
                    setMetaInfo(store, metaInfoData);
                    return [2 /*return*/];
                });
            });
        },
    },
    computed: {},
    beforeDestroy: function () {
        window.removeEventListener('resize', this.updateHeader);
    },
    beforeRouteEnter: function (to, from, next) {
        return __awaiter(this, void 0, void 0, function () {
            var conciergeServices;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, loadConciergeServices(store)];
                    case 1:
                        conciergeServices = _a.sent();
                        next(function (vm) {
                            vm.$data.conciergeServices = conciergeServices;
                        });
                        return [2 /*return*/];
                }
            });
        });
    },
});
