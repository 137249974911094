var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from "vue";
import api from "@/api";
import { siteName } from "@/config";
var shajs = require("sha.js");
export default Vue.extend({
    name: "layout-footer",
    data: function () {
        return {
            siteName: siteName,
            smsListVisible: true,
            travelInsVisible: true,
            ltrFormVisible: false,
            userFullName: "",
            userEmail: "",
            subscribeError: "",
            subscribeMammoth: true,
            subscribeLakeTahoe: true,
            subscribeSteamboat: true,
        };
    },
    computed: {
        is_mobile: function () {
            var toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i];
            // return toMatch.some((x) => navigator.userAgent.match(x));
            for (var _i = 0, toMatch_1 = toMatch; _i < toMatch_1.length; _i++) {
                var el = toMatch_1[_i];
                if (navigator.userAgent.match(el)) {
                    return true;
                }
            }
            return false;
        },
    },
    methods: {
        openSmsForm: function () {
            window.open("https://eztxt.s3.amazonaws.com/476889/widgets/5bae64023b35c6.41978157.html", null, "location=no,menubar=no,scrollbars=no,resizable=yes,status=no,toolbar=no,width=520,height=350");
        },
        closeSmsList: function () {
            this.smsListVisible = false;
            this.$cookies.set("smsListVisible", "0", "1m");
        },
        closeTravelIns: function () {
            this.travelInsVisible = false;
        },
        openLtrForm: function () {
            this.ltrFormVisible = true;
            // :href="(siteName=='nomadness')?'https://www.sierraresortrealestate.com/':'http://www.mammothmountainrealestate.com/'"
        },
        validateEmail: function (email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        signUpForExclusiveDiscounts: function () {
            return __awaiter(this, void 0, void 0, function () {
                var nonceHi, captchaLo, nonceLo, nonce, msg, result;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.subscribeError = "";
                            if (this.userFullName.length < 3) {
                                this.subscribeError = "Invalid Name";
                                return [2 /*return*/];
                            }
                            if (!this.validateEmail(this.userEmail)) {
                                this.subscribeError = "Invalid Email";
                                return [2 /*return*/];
                            }
                            nonceHi = Math.floor(new Date().getTime() / 1234567);
                            return [4 /*yield*/, api.nomadCaptcha()];
                        case 1:
                            captchaLo = _a.sent();
                            nonceLo = parseInt(captchaLo, 10);
                            nonce = nonceHi * 1234567 + nonceLo;
                            msg = {
                                nonce: nonce,
                                name: this.userFullName,
                                email: this.userEmail,
                                locations: {
                                    mammoth: this.subscribeMammoth,
                                    steamboat: this.subscribeSteamboat,
                                    lakeTahoe: this.subscribeLakeTahoe,
                                },
                                source: siteName.includes("nomadness")
                                    ? "NomadnessRentals.com"
                                    : siteName == "mrbo"
                                        ? "MammothRentalByOwner.com"
                                        : "SteamboatRentalByOwner.com",
                                checkSum: "x",
                            };
                            msg.checkSum = shajs("sha256")
                                .update(JSON.stringify(msg) + "25FD5-F9F8B-DEADB-9B2D3-95DAC-75A39")
                                .digest("hex");
                            return [4 /*yield*/, api.subscribe(msg)];
                        case 2:
                            result = _a.sent();
                            if (result.trim() === "THANK YOU!") {
                                alert("Thank you! You will receive our best offers");
                            }
                            else {
                                this.subscribeError = result;
                            }
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
    mounted: function () {
        var smsListVisibleCookie = this.$cookies.get("smsListVisible");
        if (smsListVisibleCookie !== null) {
            this.smsListVisible = "0" !== smsListVisibleCookie;
        }
    },
});
