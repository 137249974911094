var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import api from "@/api";
import * as quoteLib from '@/lib/quote';
// @ts-ignore
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
function newReview(aListing, lastSearch) {
    var popperConfig = {
        placement: 'auto',
    };
    return {
        review: {
            ListingId: aListing.pid,
            ListingNum: aListing.id,
            ListingName: aListing.title,
            Title: '',
            ReviewText: '',
            DateStayed: null,
            Rating: 0,
            Name: '',
            Email: '',
            WhereILive: '',
            SendWeeklySpecials: true,
            recaptcha: ''
        },
        validation: {},
        errors: [],
        modalOpened: false,
        emailTooltipVisible: false,
        faqVisible: true,
        SubmitBtnText: 'Submit Review',
        CanSubmit: true,
        errorMessage: '',
        dateFormat: {
            title: "MMM YYYY",
            weekdays: "W",
            navMonths: "MMM",
            input: ["L", "MM/DD/YYYY", "MM/DD/YYYY"],
            dayPopover: "L",
            data: ["L", "MM/DD/YYYY", "MM/DD/YYYY"] // For attribute dates
        },
        popperConfig: popperConfig,
    };
}
export default Vue.extend({
    name: 'review',
    props: ['listing', 'lastSearch'],
    components: { DatePicker: DatePicker },
    data: function () {
        var result = newReview(this.listing, this.lastSearch);
        return result;
    },
    mounted: function () {
        var modal = this.$refs.modalContainer;
        document.body.insertBefore(modal, document.body.firstChild);
    },
    watch: {
        "modalOpened": "disableScroll"
    },
    methods: {
        disableScroll: function () {
            document.body.style.overflow = this.modalOpened ? "hidden" : "";
        },
        onComplete: function (recaptchaToken) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (this.validate(true)) {
                        this.$refs.invisibleRecaptchaReview.execute();
                    }
                    return [2 /*return*/];
                });
            });
        },
        recaptchaCallback: function (recaptchaToken) {
            return __awaiter(this, void 0, void 0, function () {
                var response, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.review.recaptcha = recaptchaToken;
                            return [4 /*yield*/, api.review(this.review)];
                        case 1:
                            response = _a.sent();
                            this.SubmitBtnText = "Submit Review";
                            this.CanSubmit = true;
                            if (!response.error) {
                                res = newReview(this.listing, this.lastSearch);
                                this.modalOpened = false;
                                this.review = res.review;
                                this.errors = res.errors;
                                this.errorMessage = '';
                                this.$refs.wizard.reset();
                                this.review.Title = '';
                                this.review.ReviewText = '';
                                this.review.DateStayed = null;
                                this.review.Rating = 0;
                                this.review.Name = '';
                                this.review.Email = '';
                                this.review.WhereILive = '';
                                this.review.SendWeeklySpecials = true;
                                alert("Thank you for your review!");
                            }
                            else {
                                this.errorMessage = response.error;
                            }
                            return [2 /*return*/];
                    }
                });
            });
        },
        validate: function (p) {
            var v_result = quoteLib.ValidateFormFields(this.$refs);
            this.validation = v_result.validationErrors;
            var result = v_result.errors.length == 0;
            if (result && p) {
                this.$data.SubmitBtnText = "Please wait...";
                this.$data.CanSubmit = false;
            }
            return result;
        },
        setSendWeeklySpecials: function () {
            this.review.SendWeeklySpecials = !this.review.SendWeeklySpecials;
        },
        setRating: function (n) {
            if (this.review.Rating == n)
                this.review.Rating = 0;
            else
                this.review.Rating = n;
        },
        getRatingText: function () {
            switch (this.review.Rating) {
                case 1: return "poor";
                case 2: return "fair";
                case 3: return "good";
                case 4: return "very good";
                case 5: return "excellent";
                default: return "&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ";
            }
        }
    }
});
