import Vue from 'vue';
export default Vue.extend({
    name: 'footnotes',
    props: ['shortDescription', 'longDescription'],
    data: function () {
        return {
            expandFooter: false,
            read_more: 'Read More',
        };
    },
    methods: {
        toggleReadMore: function () {
            this.expandFooter = !this.expandFooter;
            this.read_more = this.expandFooter ? 'Read Less' : 'Read More';
        },
    },
});
