var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import SearchForm from '@/components/searchForm.vue';
import store, { loadLocations } from '@/store';
import { siteName, showBlog } from '@/config';
export default Vue.extend({
    components: { SearchForm: SearchForm },
    data: function () {
        var menuItems = [
            {
                itemclass: 'logo',
                route: { name: 'home' },
                label: "",
                content: {
                    class: 'logo-phone',
                    id: 'logo-phone',
                    html: '<a href="tel:+1888472-5777">(888) 472-5777</a>'
                }
            },
            {
                route: { name: 'home' },
                label: 'Home'
            },
            // {
            //   route: { name: 'discounts' },
            //   label: 'Discounts'
            // },
            {
                url: '#',
                label: 'Activities',
                submenu: siteName != 'nomadness' ? undefined : [] // will be populated later
            },
            {
                url: '#',
                label: 'About',
                submenu: [
                    { label: 'About', url: '/about-us' },
                    { label: 'Locations', url: '/locations' },
                ]
            },
            showBlog ? {
                route: { name: 'Blog' },
                label: 'BLOG'
            } : undefined,
            {
                url: '#',
                label: 'CONTACT',
                submenu: [
                    { label: 'Contact', url: '/contact' },
                    { label: 'Career', url: '/career' }
                ]
            },
            {
                route: { name: 'list-your-property' },
                label: 'List Your Property'
            },
            showBlog ? {
                route: { name: 'concierge-services' },
                label: 'Concierge'
            } : undefined,
            // !showBlog ? {
            //   url: 'http://www.nomadnesstalks.com/',
            //   label: 'BLOG',
            //   target: "_blank",
            //   itemclass: 'social-bar',
            // }: undefined,
            // {
            //   url: 'https://admin.mammothrentalbyowner.com',
            //   label: '<i class="fa fa-user"></i>',
            //   itemclass: 'login-form'
            // },
            // {
            //   url: '#',
            //   label: '<i class="fa fa-search"></i>',
            //   //itemclass: 'search-bar',
            //   itemclass: 'search-bar menu-right',
            //   //customComponent: SearchForm
            //   isSearch: true
            // },
        ].filter(function (x) { return !!x; });
        return {
            showSearchBox: false,
            scrolled: false,
            isMenuOpen: false,
            items: menuItems
        };
    },
    methods: {
        changeLogoOnScroll: function () {
            if (this.scrolled) {
                var logo = "";
                switch (siteName) {
                    case 'mrbo':
                        logo = '<img src="https://s3.amazonaws.com/nmr-cdn/img/static/mrbo_logo.png" alt="logo" />';
                        break;
                    case 'steam':
                        logo = '<img src="https://s3.amazonaws.com/nmr-cdn/img/static/steamboat_logo.png" alt="logo" />';
                        break;
                    default:
                        logo = '<img src="https://s3.amazonaws.com/nmr-cdn/img/static/nomadness_logo_black.png" alt="logo" style="width: 177px"/>';
                        break;
                }
                this.items[0].label = logo;
            }
            else {
                var logo = "";
                switch (siteName) {
                    case 'mrbo':
                        logo = '<img src="https://s3.amazonaws.com/nmr-cdn/img/static/mrbo_logo.png" alt="logo" />';
                        break;
                    case 'steam':
                        logo = '<img src="https://s3.amazonaws.com/nmr-cdn/img/static/steamboat_logo.png" alt="logo" />';
                        break;
                    default:
                        logo = '<img src="https://s3.amazonaws.com/nmr-cdn/img/static/nomadness_logo_white.png" alt="logo" style="width: 177px"/>';
                        break;
                }
                this.items[0].label = logo;
            }
            // siteName === 'mrbo' ? '<img src="https://s3.amazonaws.com/nmr-cdn/img/static/mrbo_logo.png" alt="logo" />' :
            //            siteName === 'steam' ? '<img src="https://s3.amazonaws.com/nmr-cdn/img/static/steamboat_logo.png" alt="logo" />' :
            //            '<img src="https://s3.amazonaws.com/nmr-cdn/img/static/nomadness_logo.png" alt="logo" />'
        },
        isExternalUrl: function (s) {
            return s && s.startsWith("http");
        },
        focus_search_box: function () {
            this.isMenuOpen = false;
            this.showSearchBox = !this.showSearchBox;
            this.$nextTick(function () {
                try {
                    for (var _i = 0, _a = Array.from(document.getElementsByClassName('txt_find_listing_by_id')); _i < _a.length; _i++) {
                        var el = _a[_i];
                        if (el.focus())
                            break; // stop if we successfully focused (we can only focus a visible element)
                    }
                }
                catch (e) {
                    console.error(e);
                }
            });
            return false;
        },
        ignore_this: function () {
            return false;
        },
        handleScroll: function () {
            this.scrolled = window.scrollY > 0;
        },
        onMounted: function () {
            return __awaiter(this, void 0, void 0, function () {
                var allLocations, activitiesMenu, mnu;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, loadLocations(store)];
                        case 1:
                            allLocations = _a.sent();
                            activitiesMenu = this.items.find(function (x) { return x.label == "Activities"; });
                            // { url: '/activities/baltimore/event-calendar', label: 'Baltimore' }
                            if (allLocations.subPages.length > 1) {
                                activitiesMenu.submenu = allLocations.subPages.map(function (x, i) {
                                    var shortTitle = x.title.split(/[\s,]+/).filter(function (p) { return p.length > 3; }).join(' ');
                                    return {
                                        url: "/activities/".concat(x.slug),
                                        label: shortTitle // x.title.split(' ')[0].replace(/,/gi, '')
                                    };
                                });
                            }
                            else {
                                activitiesMenu.url = "/activities/".concat(allLocations.subPages[0].slug);
                                mnu = this.items;
                                mnu.splice(mnu.findIndex(function (x) { return x.label == "Locations"; }), 1);
                                this.items = mnu;
                            }
                            return [2 /*return*/];
                    }
                });
            });
        },
        hideSearchBoxChange: function () {
            if (this.isMenuOpen) {
                this.showSearchBox = false;
            }
        }
    },
    mounted: function () {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('scroll', this.changeLogoOnScroll);
        this.onMounted();
    },
    beforeMount: function () {
        this.changeLogoOnScroll();
    },
    beforeDestroy: function () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    watch: {
        'isMenuOpen': 'hideSearchBoxChange'
    }
});
