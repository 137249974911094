var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from 'vue';
import api from '@/api';
import { preloadImage } from '@/api';
import { siteName } from '@/config';
// @ts-ignore
import Calendar from 'v-calendar/lib/components/calendar.umd';
import { RRule } from 'rrule';
export default Vue.extend({
    name: 'activity-list-item',
    props: ['activity'],
    components: { Calendar: Calendar },
    data: function () {
        return {
            eventTitleColors: ['#42a9d3', '#66BB6A', '#E64A19', '#689F38'],
            eventImages: {},
            preloadCounter: 0,
            selectedYear: new Date().getFullYear(),
            selectedMonth: new Date().getMonth() + 1,
            monthlyEvents: [],
            dailyEventsMemo: {},
            calendarStyles: {
                wrapper: {
                    backgroundColor: 'transparent',
                    paneWidth: 'auto',
                    color: 'black',
                },
            },
            calendarFormats: {
                title: 'MMMM YYYY',
                weekdays: 'WWW',
                navMonths: 'MMM',
                input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
                dayPopover: 'L', // Only for `v-date-picker`
            },
            calendarAttributes: [
                {
                    popover: {
                        label: '',
                    },
                    key: 'today',
                    highlight: {
                        backgroundColor: '#ff8080',
                    },
                    dates: new Date(2018, 0, 1)
                }
            ]
        };
    },
    mounted: function () {
        this.filterEvents();
    },
    methods: {
        formatDate: function (date) {
            return api.formatDate(date);
        },
        onMonthChange: function (data) {
            this.selectedYear = data.year;
            this.selectedMonth = data.month;
            this.filterEvents();
        },
        filterEvents: function () {
            var from_date = new Date(this.selectedYear, this.selectedMonth - 1, 1, 0, 0, 0, 0);
            var to_date = new Date(this.selectedYear, this.selectedMonth, 0, 23, 59);
            var result = this.getRegularEvents(from_date.getTime(), to_date.getTime());
            result.push.apply(result, this.getRecurringEventList(from_date.getTime(), to_date.getTime()));
            result.sort(function (a, b) { return new Date(a.event_date).getTime() - new Date(b.event_date).getTime(); });
            this.monthlyEvents = result;
        },
        toRRuleDateFromat: function (date) {
            var dateString = date.toISOString().split('.')[0] + 'Z';
            while (dateString.includes('-') || dateString.includes(':')) {
                dateString = dateString.replace('-', '').replace(':', '');
            }
            return dateString;
        },
        toOnlyDate: function (dateString) {
            return new Date(new Date(dateString).setHours(0, 0, 0, 0));
        },
        toTime: function (dateString) {
            return this.toOnlyDate(dateString).getTime();
        },
        getRecurringDates: function (fromDate, toDate, recurringType, monthDay, weekDay, weekNumber) {
            if (monthDay === void 0) { monthDay = null; }
            if (weekDay === void 0) { weekDay = null; }
            if (weekNumber === void 0) { weekNumber = null; }
            var weekly = 10, biWeekly = 20, dayOnMonth = 30, weekDayOnMonth = 40;
            var sunday = 1, monday = 2, tuesday = 3, wednesday = 4, thursday = 5, friday = 6, saturday = 7;
            var isValidRecurringType = recurringType == weekly || recurringType == biWeekly || recurringType == dayOnMonth || recurringType == weekDayOnMonth;
            fromDate.setUTCHours(0, 0, 0, 0);
            toDate.setUTCHours(0, 0, 0, 0);
            var result = "DTSTART:".concat(this.toRRuleDateFromat(fromDate), "\nRRULE:UNTIL=").concat(this.toRRuleDateFromat(toDate), ";");
            if (recurringType == weekly) {
                result += 'FREQ=WEEKLY';
            }
            else if (recurringType == biWeekly) {
                result += 'FREQ=WEEKLY;INTERVAL=2';
            }
            else if (recurringType == dayOnMonth) {
                var monthDayNumber = monthDay < 29 ? monthDay : -1;
                result += "FREQ=MONTHLY;BYMONTHDAY=".concat(monthDayNumber);
            }
            else if (recurringType == weekDayOnMonth) {
                var weekDayName = "";
                switch (weekDay) {
                    case sunday:
                        weekDayName = 'SU';
                        break;
                    case monday:
                        weekDayName = 'MO';
                        break;
                    case tuesday:
                        weekDayName = 'TU';
                        break;
                    case wednesday:
                        weekDayName = 'WE';
                        break;
                    case thursday:
                        weekDayName = 'TH';
                        break;
                    case friday:
                        weekDayName = 'FR';
                        break;
                    case saturday:
                        weekDayName = 'SA';
                        break;
                    default: return [];
                }
                result += "FREQ=MONTHLY;BYDAY=".concat(weekNumber).concat(weekDayName);
            }
            if (!isValidRecurringType) {
                return [];
            }
            var rule = RRule.fromString(result);
            return [rule.all(), rule.toText()];
        },
        getRegularEvents: function (fromDateTime, toDateTime) {
            var _this = this;
            return this.activity.subPages.filter(function (event) {
                return toDateTime >= _this.toTime(event.event_date) &&
                    fromDateTime <= _this.toTime(event.event_end_date) &&
                    !event.recurring_type;
            });
        },
        getReccurringEvents: function (fromDateTime, toDateTime) {
            var _this = this;
            return this.activity.subPages
                .filter(function (event) {
                return toDateTime >= _this.toTime(event.event_date) &&
                    fromDateTime <= _this.toTime(event.event_end_date) &&
                    event.recurring_type;
            });
        },
        getValidatedRecurringDates: function (event) {
            var dayOnMonth = 30, weekDayOnMonth = 40;
            var start = new Date(event.event_date);
            var end = new Date(event.event_end_date);
            var recurringType = event.recurring_type;
            var monthDay = event.recurring_month_day;
            var weekDay = event.recurring_week_day;
            var weekNumber = event.recurring_week_number;
            var recurringDates = [];
            if (recurringType == dayOnMonth && monthDay) {
                recurringDates = this.getRecurringDates(start, end, recurringType, monthDay);
            }
            else if (recurringType == weekDayOnMonth && weekDay && weekNumber) {
                recurringDates = this.getRecurringDates(start, end, recurringType, null, weekDay, weekNumber);
            }
            else {
                recurringDates = this.getRecurringDates(start, end, recurringType);
            }
            return recurringDates;
        },
        getRecurringEventList: function (fromDateTime, toDateTime) {
            var _this = this;
            var allRecurringEvents = [];
            this
                .getReccurringEvents(fromDateTime, toDateTime)
                .forEach(function (event) {
                var recurringDatesWithTitle = _this.getValidatedRecurringDates(event);
                if (recurringDatesWithTitle.length) {
                    var recurringDates = recurringDatesWithTitle[0], recurringTitle = recurringDatesWithTitle[1];
                    // @ts-ignore
                    var isInRage = recurringDates.some(function (date) {
                        date.setHours(0, 0, 0, 0);
                        var time = date.getTime();
                        return time >= fromDateTime && time <= toDateTime;
                    });
                    if (!isInRage) {
                        return;
                    }
                    var recurringEvent = __assign({}, event);
                    recurringEvent.title += ' - ' + recurringTitle;
                    allRecurringEvents.push(recurringEvent);
                }
            });
            return allRecurringEvents;
        },
        getRecurringEventByDate: function (time) {
            var _this = this;
            var allRecurringEvents = [];
            var recurringEventDatesIndex = 0;
            this
                .getReccurringEvents(time, time)
                .forEach(function (event) {
                var recurringDates = _this.getValidatedRecurringDates(event);
                if (recurringDates.length) {
                    var recurringEvent = recurringDates[recurringEventDatesIndex]
                        // @ts-ignore
                        .filter(function (recurringDate) {
                        recurringDate.setHours(0, 0, 0, 0);
                        return time == recurringDate.getTime();
                    })
                        // @ts-ignore
                        .map(function (recurringDate) {
                        var eventByDate = __assign({}, event);
                        eventByDate.event_date = recurringDate;
                        eventByDate.event_end_date = recurringDate;
                        return eventByDate;
                    });
                    allRecurringEvents.push.apply(allRecurringEvents, recurringEvent);
                }
            });
            return allRecurringEvents;
        },
        formatTitle: function (title, colorIndex) {
            colorIndex %= this.eventTitleColors.length;
            return "<div style=\"background: ".concat(this.eventTitleColors[colorIndex], "\" class=\"event-inner-title\">").concat(title, "</div>");
        },
        goToEvent: function (clickEvent, eventId) {
            clickEvent.stopPropagation();
            var element = document.getElementById('event-' + eventId);
            var headerOffset = 80;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.scrollY - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        },
        goToEventFromCell: function (clickEvent, date) {
            var dailyEvents = this.dailyEvents(date);
            if (!dailyEvents.length) {
                return;
            }
            var firstEventIndex = 0;
            this.goToEvent(clickEvent, dailyEvents[firstEventIndex].id);
        },
        calendarDayClass: function (date) {
            return this.dailyEvents(date).length > 0 ? 'calendar-has-events' : '';
        },
        dailyEvents: function (date) {
            var time = date.getTime();
            var memo = this.dailyEventsMemo[time];
            if (memo) {
                return memo;
            }
            var result = this.getRegularEvents(time, time);
            result.push.apply(result, this.getRecurringEventByDate(time));
            this.dailyEventsMemo[time] = result;
            return result;
        },
        imageURL: function (pageId) {
            var _this = this;
            if (!this.eventImages[pageId]) {
                this.eventImages[pageId] = "https://s3.amazonaws.com/nmr-cdn/img/".concat(siteName, "/").concat(pageId, "/t/1.jpg");
                this.preloadCounter += 1;
                preloadImage("https://s3.amazonaws.com/nmr-cdn/img/".concat(siteName, "/").concat(pageId, "/lg/1.jpg")).then(function (url) {
                    _this.eventImages[pageId] = url;
                    _this.preloadCounter -= 1;
                });
            }
            return this.preloadCounter >= 0 ? this.eventImages[pageId] : '';
        },
        shortenBody: function (html) {
            var doc = new DOMParser().parseFromString(html, 'text/html');
            var result = (doc.body.textContent || "").replace(/&nbsp;/gi, ' ').replace(' ', ' ').substr(0, 183) + "...";
            return result;
        },
    }
});
