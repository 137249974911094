var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as seo from '@/router/seo';
import store, { loadLocations, loadListings, setMetaInfo } from '@/store';
import PageComponent from '@/lib/page-component';
import { siteName } from '@/config';
import footnotes from '@/components/footnotes.vue';
export default PageComponent.extend({
    name: 'locations',
    components: { footnotes: footnotes },
    data: function () {
        return {
            locations: null,
            expandFooter: false
        };
    },
    methods: {
        coordinates: function (l) {
            return {
                lat: l.location_lat,
                lng: l.location_lon,
            };
        },
        imageURL: function (location) {
            return "https://s3.amazonaws.com/nmr-cdn/img/".concat(siteName, "/").concat(location.location_picture, "/xl/1.jpg");
        },
        updateHeader: function () {
            if (!this.locations) {
                return;
            }
            this.setTitle('Locations');
            this.setBackgroundLink('');
            this.setBreadcrumbs([
                { label: 'Home', route: { name: 'home' } },
                { label: 'Locations', route: { name: 'locations' } },
            ]);
            var metaInfoData = {
                title: this.locations.meta_title,
                meta: [{
                        vmid: 'description',
                        name: 'description',
                        content: this.locations.meta_description
                    }, {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: this.locations.meta_keywords
                    }],
                script: seo.getLdJson(seo.getDefaultPageLocation())
            };
            setMetaInfo(store, metaInfoData);
        },
        showLocationProperties: function (location) {
            this.$router.push({
                name: 'rentals-search-by-location',
                params: { locationSlug: location.slug }
            });
        }
    },
    watch: {
        'locations': 'updateHeader'
    },
    beforeRouteEnter: function (to, from, next) {
        return __awaiter(this, void 0, void 0, function () {
            var locations, listings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, loadLocations(store)];
                    case 1:
                        locations = _a.sent();
                        return [4 /*yield*/, loadListings(store)];
                    case 2:
                        listings = _a.sent();
                        next(function (vm) {
                            locations.subPages.forEach(function (subPage) {
                                var listingsFromLocation = listings.filter(function (l) { return l.locationid === subPage.location; });
                                subPage.hasListings = listingsFromLocation.some(function (l) { return l.options.isShortTerm; });
                                subPage.hasLongTermListings = listingsFromLocation.some(function (l) { return l.options.isLongTerm; });
                            });
                            vm.$data.locations = locations;
                        });
                        return [2 /*return*/];
                }
            });
        });
    },
});
