var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import * as quoteLib from '@/lib/quote';
import api from '@/api';
import { siteName, platform_apiRoot } from '@/config';
import store, { loadLocations } from '@/store';
import { getCookie } from '@/lib/tools';
export default Vue.extend({
    name: 'reservation-preview',
    props: ['quote', 'quotePrice'],
    data: function () {
        var result = {
            platform_apiRoot: platform_apiRoot,
            confirmation_url: '',
            reservation: null
        };
        return result;
    },
    computed: {
        nights: function () {
            return quoteLib.datediff(this.quote.Depart, this.quote.Arrive);
        }
    },
    methods: {
        onEnter: function () {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var _b, allLocations, thisLocation, fbData, fbp, result, e_1, sessionID, referrerID, userAgent;
                var _this = this;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _b = this;
                            return [4 /*yield*/, api.book(this.quote, this.quotePrice.quotePrice)];
                        case 1:
                            _b.reservation = _c.sent();
                            if (!(this.reservation && this.reservation.confirmation)) return [3 /*break*/, 8];
                            this.confirmation_url = platform_apiRoot + "/res/print.html?Id=" + this.reservation.confirmation.uuid;
                            _c.label = 2;
                        case 2:
                            _c.trys.push([2, 6, , 7]);
                            return [4 /*yield*/, loadLocations(store)];
                        case 3:
                            allLocations = _c.sent();
                            thisLocation = (_a = allLocations.subPages) === null || _a === void 0 ? void 0 : _a.find(function (l) { return l.id == _this.quote.Listing.locationid; });
                            this.$gtag.event('BookingFormSuccess', {
                                'event_category': thisLocation.slug,
                                'event_label': 'Reservation',
                                'value': this.quotePrice.quotePrice
                            });
                            this.$gtag.purchase({
                                'transaction_id': this.reservation.confirmation.pubResId,
                                'affiliation': thisLocation.slug,
                                'value': this.quotePrice.quotePrice,
                                'currency': 'USD',
                                'tax': this.quotePrice.quoteTotalTax,
                                'items': [
                                    {
                                        'id': this.quote.ListingId,
                                        'item_name': this.quote.Listing.title,
                                        'affiliation': thisLocation.slug,
                                        'item_category': thisLocation.slug,
                                        'quantity': 1,
                                        'price': this.quotePrice.quotePrice
                                    }
                                ]
                            });
                            fbData = {
                                data: [
                                    {
                                        event_name: "Purchase",
                                        event_time: Math.floor(Date.now() / 1000),
                                        user_data: {
                                            client_user_agent: navigator.userAgent,
                                        },
                                        custom_data: {
                                            currency: "usd",
                                            value: this.quotePrice.quotePrice,
                                            contents: [
                                                {
                                                    id: this.reservation.confirmation.pubResId,
                                                    quantity: 1,
                                                    delivery_category: "home_delivery"
                                                }
                                            ]
                                        },
                                        event_source_url: window.location.href,
                                        action_source: 'website'
                                    }
                                ]
                            };
                            //@ts-ignore
                            if (this.$router.fbc)
                                fbData.data[0].user_data.fbc = this.$router.fbc;
                            fbp = getCookie("_fbp");
                            if (!fbp) return [3 /*break*/, 5];
                            fbData.data[0].user_data.fbp = fbp;
                            return [4 /*yield*/, api.facebookEvent(fbData)];
                        case 4:
                            result = _c.sent();
                            console.log(result);
                            _c.label = 5;
                        case 5: return [3 /*break*/, 7];
                        case 6:
                            e_1 = _c.sent();
                            console.error(e_1);
                            return [3 /*break*/, 7];
                        case 7:
                            // send notification to server
                            try {
                                sessionID = this.$router.sessionID;
                                referrerID = this.$router.referrerID;
                                userAgent = navigator.userAgent;
                                navigator.sendBeacon("".concat(platform_apiRoot, "/res/api/pageView.cfm?w=").concat(siteName, "&q=/book/").concat(this.quote.Listing.pid, "-").concat(encodeURIComponent(this.reservation.confirmation.uuid), "&ref=").concat(encodeURIComponent(referrerID), "&sid=").concat(encodeURIComponent(sessionID), "&ua=").concat(encodeURIComponent(userAgent)));
                            }
                            catch (e) {
                                // ignore
                            }
                            _c.label = 8;
                        case 8: return [2 /*return*/];
                    }
                });
            });
        },
        validate: function (event, displayList) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, null];
                });
            });
        },
        printConfirmation: function () {
            window.open(this.confirmation_url);
        }
    }
});
