import Vue from 'vue';
import * as types from '@/types';
import { getPageImageURL, isMobile, preloadImage } from '@/api';
export default Vue.extend({
    name: 'testimonials',
    props: ['items'],
    data: function () {
        return {
            imageUrl: {},
            preloadCounter: 0
        };
    },
    methods: {
        getImageURL: function (item) {
            var _this = this;
            if (!this.imageUrl[item.id]) {
                this.imageUrl[item.id] = getPageImageURL(item.id, isMobile() ? types.ImageSize.tiny : types.ImageSize.large);
                if (isMobile()) {
                    this.preloadCounter += 1;
                    preloadImage(getPageImageURL(item.id, types.ImageSize.large)).then(function (url) {
                        _this.imageUrl[item.id] = url;
                        _this.preloadCounter -= 1;
                    });
                }
            }
            return this.preloadCounter >= 0 ? this.imageUrl[item.id] : '';
        },
    }
});
