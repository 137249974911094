import Vue from 'vue';
import * as types from '@/types';
import { getImageURL } from '@/api';
// @ts-ignore
import VueSkeletonLoader from 'skeleton-loader-vue';
export default Vue.extend({
    name: 'listing-slider',
    props: ['images', 'matterport', 'showSkeleton'],
    components: { VueSkeletonLoader: VueSkeletonLoader },
    methods: {
        getImageURL: function (image) {
            return getImageURL(image, types.ImageSize.large);
        },
        getThumbnailURL: function (image) {
            return getImageURL(image, types.ImageSize.tiny);
        },
        goToSlide: function (index) {
            var slider = this.$refs.slider;
            slider.goToPage(index);
        },
        rotateForward: function () {
            var slider = this.$refs.vertSlider;
            var children = slider.children;
            var firstChild = children[0];
            var lastChild = children[children.length - 1];
            slider.insertBefore(lastChild, firstChild);
        },
        rotateBackward: function () {
            var slider = this.$refs.vertSlider;
            var children = slider.children;
            var firstChild = children[0];
            var lastChild = children[children.length - 1];
            slider.insertBefore(firstChild, lastChild.nextSibling);
        }
    }
});
