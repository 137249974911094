var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import searchBar from '@/components/search-bar.vue';
import listingSlider from './listing-slider.vue';
import api from '@/api';
import store, { setIsLoading, lastSearch, loadLocations, loadDiscounts } from '@/store';
import PageComponent from '@/lib/page-component';
import { Price } from '@/lib/property';
import * as quoteLib from '@/lib/quote';
import reservation from "./reservation/index.vue";
import inquiry from "./inquiry/index.vue";
import writereview from "./review/index.vue";
// import imgoing from "./imgoing.vue"
// @ts-ignore
import Calendar from 'v-calendar/lib/components/calendar.umd';
function routeUpdate(to, from, next) {
    return __awaiter(this, void 0, Promise, function () {
        var id, listing, d, i, discountData, reviews, rating_avg;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(store, true);
                    id = parseInt(to.params.id, 10);
                    return [4 /*yield*/, api.listing(id)];
                case 1:
                    listing = _a.sent();
                    d = listing.availabilityFirstDate;
                    for (i = 0; i < 30; i++) {
                        d = quoteLib.addDays(d, 1);
                    }
                    return [4 /*yield*/, loadDiscounts(store)];
                case 2:
                    discountData = _a.sent();
                    return [4 /*yield*/, api.reviews(id)];
                case 3:
                    reviews = _a.sent();
                    rating_avg = 0;
                    if (reviews && reviews.length > 0) {
                        rating_avg = reviews.reduce(function (sum, el) { return sum + parseInt(el.rating); }, 0) / reviews.length;
                    }
                    next(function (vm) {
                        vm.$data.listing = listing;
                        vm.$data.discounts = discountData.discounts.filter(function (x) { return x.listingIDs.includes(listing.id.toString()); });
                        vm.$data.reviews = reviews;
                        vm.$data.numReviews = reviews ? reviews.length : 0;
                        vm.$data.rating_avg = rating_avg;
                        vm.$data.lastSearch = lastSearch(store);
                        setIsLoading(store, false);
                    });
                    return [2 /*return*/];
            }
        });
    });
}
export default PageComponent.extend({
    name: 'listing',
    components: { listingSlider: listingSlider, searchBar: searchBar, reservation: reservation, inquiry: inquiry, writereview: writereview, Calendar: Calendar },
    props: ['id', 'slug'],
    data: function () {
        return {
            lastSearch: "N/A",
            listing: null,
            calendarStyles: {
                wrapper: {
                    backgroundColor: 'transparent',
                    color: 'black',
                },
            },
            calendarFormats: {
                title: 'MMMM YYYY',
                weekdays: 'WWW',
                navMonths: 'MMM',
                input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
                dayPopover: 'L', // Only for `v-date-picker`
            },
            calendarAttributes: [
                {
                    key: 'today',
                    highlight: {
                        backgroundColor: '#ff8080',
                    },
                    dates: new Date(2018, 0, 1)
                }
            ],
            discounts: [],
            reviews: [],
            numReviews: 0,
            rating_avg: 0,
            ratesTableVisible: false,
            discountsVisible: false,
            locations: {}
        };
    },
    computed: {
        isLoading: function () {
            return store.state.isLoading;
        }
    },
    methods: {
        hasReviews: function () {
            return this.numReviews > 0;
        },
        getLocationText: function (locationID) {
            return this.locations[locationID];
        },
        formatDate: function (date) {
            return api.formatDate(date);
        },
        formatPrice: function (price) {
            return '$' + price.toFixed(2);
        },
        color: function (index) {
            switch (index % 4) {
                case 0: return 'color-2';
                case 1: return 'color-3';
                case 2: return 'color-1';
                case 3: return 'color-4';
                default: return 'color-2';
            }
        },
        calendarDayClass: function (day) {
            var css = day.isToday ? 'currentDay ' : '';
            if (this.IsAvailable(day.date) || this.isArrivalDay(day)) {
                css += 'has-events-rentals-free ';
            }
            else {
                css += 'has-events-rentals';
            }
            return css;
        },
        isArrivalDay: function (day) {
            var nextDay = new Date(day.date);
            nextDay.setDate(nextDay.getDate() + 1);
            var prevDay = new Date(day.date);
            prevDay.setDate(prevDay.getDate() - 1);
            return !this.IsAvailable(day.date) && this.IsAvailable(prevDay);
        },
        isDepartureDay: function (day) {
            var prevDay = new Date(day.date);
            prevDay.setDate(prevDay.getDate() - 1);
            return this.IsAvailable(day.date) && !this.IsAvailable(prevDay);
        },
        IsAvailable: function (date) {
            return quoteLib.IsAvailable(this.listing, date);
        },
        Price: function (date) {
            return Price(this.listing, date, date);
        },
        StartDayName: function (day) {
            switch (day) {
                case 0: return "Any Day";
                case 1: return "Monday";
                case 2: return "Tuesday";
                case 3: return "Wednesday";
                case 4: return "Thursday";
                case 5: return "Friday";
                case 6: return "Saturday";
                case 7: return "Sunday";
            }
            return "N/A";
        },
        updateHeader: function () {
            if (!this.listing) {
                return;
            }
            this.setTitle('');
            this.setBackgroundLink('');
            this.setBreadcrumbs([
                { label: 'Home', route: { name: 'home' } },
                { label: 'Properties' },
                // { label: this.listing.location },
                { label: 'Rentals' },
                { label: "Listing ".concat(this.listing.id) },
            ]);
        },
        updateReviewScripts: function () {
            var _this = this;
            // Some reviews include custom JS (e.g. Yelp's reviews do that)
            // We need to do extra work in order to evaluate this JS.
            // Note: Yes, this allows third-parties to run code on our site.
            Vue.nextTick(function () {
                var scripts = _this.$el.querySelectorAll('#reviews .review script');
                Array.prototype.forEach.call(scripts, function (script) {
                    var newScript = document.createElement("script");
                    newScript.src = script.getAttribute('src');
                    _this.$el.appendChild(newScript);
                });
            });
        },
        toggleRates: function () {
            this.ratesTableVisible = !this.ratesTableVisible;
        },
        toggleDiscounts: function () {
            this.discountsVisible = !this.discountsVisible;
        }
    },
    watch: {
        'listing': 'updateHeader',
        'reviews': 'updateReviewScripts',
    },
    mounted: function () {
        return __awaiter(this, void 0, void 0, function () {
            var allLocations, locationsMap, _i, _a, rec, myCss, style;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, loadLocations(store)];
                    case 1:
                        allLocations = _b.sent();
                        locationsMap = {};
                        for (_i = 0, _a = allLocations.subPages; _i < _a.length; _i++) {
                            rec = _a[_i];
                            locationsMap[rec.id] = rec.title.split(' ')[0];
                        }
                        this.$data.locations = locationsMap;
                        myCss = "#chat-widget-container {\n      display: none;\n    }";
                        style = document.createElement('style');
                        style.appendChild(document.createTextNode(myCss));
                        document.getElementsByTagName('head')[0].appendChild(style);
                        return [2 /*return*/];
                }
            });
        });
    },
    beforeDestroy: function () {
        // @ts-ignore
        this.$refs.dlgRes.modalOpened = false;
        // @ts-ignore
        this.$refs.dlgInquiry.modalOpened = false;
        // @ts-ignore
        // this.$refs.dlgImgoing.modalOpened = false
        document.getElementById('booknow').classList.remove('modal-container-opened');
        document.getElementById('inquiry').classList.remove('modal-container-opened');
        // document.getElementById('imgoing').classList.remove('modal-container-opened')
    },
    beforeRouteUpdate: function (to, from, next) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, routeUpdate(to, from, function (callback) {
                            callback(_this);
                            _this.$forceUpdate();
                            next();
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
    beforeRouteEnter: function (to, from, next) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, routeUpdate(to, from, next)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
});
