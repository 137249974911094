import Vue from 'vue';
import store, * as storeAPI from '@/store/index';
var pageComponent = Vue.extend({
    methods: {
        // imageURL(pictureId: string) {
        //     // TODO: fix this hack
        //     // return `/uploads/images/${pictureId}`
        //     return `https://www.nomadnessrentals.com/uploads/images/${pictureId}`
        // },
        setTitle: function (title) {
            storeAPI.setTitle(store, title);
        },
        setBackgroundLink: function (link) {
            storeAPI.setBackgroundLink(store, link);
        },
        setBreadcrumbs: function (crumbs) {
            storeAPI.setBreadcrumbs(store, crumbs);
        },
        setMetaInfo: function (metaInfo) {
            storeAPI.setMetaInfo(store, metaInfo);
        },
        setIsLoading: function (loading) {
            storeAPI.setIsLoading(store, loading);
        },
        setLastSearch: function (range) {
            storeAPI.setLastSearch(store, range);
        }
    },
    computed: {
        backgroundLink: function () {
            return storeAPI.backgroundLink(store);
        },
        breadcrumbs: function () {
            return storeAPI.breadcrumbs(store);
        },
        getMetaInfo: function () {
            return storeAPI.getMetaInfo(store);
        },
        title: function () {
            return storeAPI.title(store);
        },
        isLoading: function () {
            return store.state.isLoading;
        },
        isLoadingListing: function () {
            return store.state.loadingImgData.isLoadingListing;
        }
    }
});
export default pageComponent;
