import Vue from 'vue';
import { preloadImage } from '@/api';
export default Vue.extend({
    name: 'property-thumbnail-image',
    props: ['item', 'width', 'height'],
    data: function () {
        return {
            item_thumbnail: '',
            item_id: 0
        };
    },
    mounted: function () {
        this.updateimg();
    },
    updated: function () {
        this.updateimg();
    },
    watch: {
        'item': {
            handler: function (newValue) {
                this.updateimg();
            }
        }
    },
    methods: {
        updateimg: function () {
            if (this.item && this.item.id != this.item_id) {
                this.item_id = this.item.id;
                this.item_thumbnail = this.item.thumbnail();
                var betterRes_1 = this.item.mainImage();
                var self_1 = this;
                preloadImage(betterRes_1).then(function () { return self_1.item_thumbnail = betterRes_1; });
            }
        }
    }
});
