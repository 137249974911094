var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from "vue";
import * as quoteLib from "@/lib/quote";
import api from "@/api";
// @ts-ignore
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
export default Vue.extend({
    name: "reservation-availability",
    props: ["quote", "errors"],
    components: {
        DatePicker: DatePicker
    },
    data: function () {
        var popperConfig = {
            placement: 'bottom',
            modifiers: [
                {
                    name: 'flip',
                    enabled: false
                }
            ]
        };
        var date = this.quote.Listing.availabilityFirstDate;
        var prevdate = this.quote.Listing.availabilityFirstDate;
        var occupiedDates = [];
        var bookedDates = [];
        for (var _i = 0, _a = this.quote.Listing.availability; _i < _a.length; _i++) {
            var available = _a[_i];
            if (!available && !quoteLib.IsAvailable(this.quote.Listing, prevdate)) { // skip adding arrival date, so we can book reservations leaving on this date
                bookedDates.push(date);
            }
            if (!available) {
                occupiedDates.push(date);
            }
            prevdate = date;
            date = new Date(date.valueOf());
            date.setHours(0, 0, 0, 0);
            date.setDate(date.getDate() + 1);
        }
        var today = new Date(new Date().setHours(0, 0, 0, 0)); // + 24*60*60*1000
        var day_after_today = new Date(new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000);
        var one_year_from_now = new Date(day_after_today.getTime() + 365 * 24 * 60 * 60 * 1000);
        var one_week_from_now = new Date(day_after_today.getTime() + 7 * 24 * 60 * 60 * 1000);
        var defArrive = this.quote.Arrive; // || day_after_today;
        var defDepart = this.quote.Depart; // || one_week_from_now;
        return {
            popperConfig: popperConfig,
            listingId: this.quote.Listing.id,
            bookedDates: bookedDates,
            calendarAttrs: [
                {
                    key: 'occupied',
                    highlight: {
                        contentStyle: {
                            color: 'red',
                            opacity: 0.5,
                            fontWeight: 'bold'
                        }
                    },
                    dates: occupiedDates,
                }
            ],
            dateFormat: {
                title: "MMM YYYY",
                weekdays: "W",
                navMonths: "MMM",
                input: ["L", "MM/DD/YYYY", "MM/DD/YYYY"],
                dayPopover: "L",
                data: ["L", "MM/DD/YYYY", "MM/DD/YYYY"] // For attribute dates
            },
            selectedRange: (defArrive ?
                {
                    start: defArrive,
                    end: defDepart
                }
                :
                    undefined),
            adults: 2,
            children: 0,
            pets: 0,
            selectedDiscount: null,
            allDiscounts: {
                forPeriod: null,
                list: [
                    { code: "", name: "Please select a period first" }
                ]
            },
            today: today,
            one_year_from_now: one_year_from_now,
            debouncedTimer: undefined
        };
    },
    mounted: function () {
        if (this.selectedRange && this.selectedRange.start != undefined && this.selectedRange.end != undefined) {
            this.validate();
        }
    },
    updated: function () {
        if (this.listingId != this.quote.Listing.id) {
            var date = this.quote.Listing.availabilityFirstDate;
            var bookedDates = [];
            for (var _i = 0, _a = this.quote.Listing.availability; _i < _a.length; _i++) {
                var available = _a[_i];
                if (!available) {
                    bookedDates.push(date);
                }
                date = new Date(date.valueOf());
                date.setDate(date.getDate() + 1);
            }
            this.bookedDates = bookedDates;
            this.listingId = this.quote.Listing.id;
            var defArrive = this.quote.Arrive; // || day_after_today;
            var defDepart = this.quote.Depart; // || one_week_from_now;
            this.selectedRange = (defArrive ?
                {
                    start: defArrive,
                    end: defDepart
                }
                :
                    undefined);
        }
    },
    watch: {
        'selectedDiscount': 'validate'
    },
    methods: {
        displayDiscount: function (discount) {
            return "".concat(discount.name || discount.code);
        },
        useCustomDiscount: function (code) {
            var customDiscount = {
                code: code,
                name: "Custom discount code: " + code
            };
            this.allDiscounts.list.push(customDiscount);
            this.selectedDiscount = customDiscount;
        },
        onDiscountSelected: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.validate()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        validate: function () {
            return __awaiter(this, void 0, Promise, function () {
                var range, sameDateValidationError;
                var _this = this;
                return __generator(this, function (_a) {
                    if (this.debouncedTimer) {
                        clearTimeout(this.debouncedTimer);
                    }
                    range = this.selectedRange;
                    if (range == null) {
                        this.quote.errors = null;
                        return [2 /*return*/, ["Please select period"]];
                    }
                    else if (range.start.getTime() === range.end.getTime()) {
                        this.quote.errors = null;
                        sameDateValidationError = ["Invalid request data!"];
                        this.$emit("show-errors", sameDateValidationError);
                        return [2 /*return*/, sameDateValidationError];
                    }
                    this.debouncedTimer = setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                        var quote, result, validationErrors, e_1, validationErrors, res_discounts, d, showCFAR;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    quote = this.quote;
                                    _a.label = 1;
                                case 1:
                                    _a.trys.push([1, 3, , 4]);
                                    return [4 /*yield*/, api.validate_availability(this.quote.Listing.pid, range.start, range.end, this.adults, this.children, this.pets, this.selectedDiscount ? this.selectedDiscount.code : '')];
                                case 2:
                                    result = _a.sent();
                                    if (result[0] != "1") {
                                        validationErrors = [result[1]];
                                        this.$emit("show-errors", validationErrors);
                                        return [2 /*return*/, validationErrors];
                                    }
                                    return [3 /*break*/, 4];
                                case 3:
                                    e_1 = _a.sent();
                                    validationErrors = [e_1.message];
                                    this.$emit("show-errors", validationErrors);
                                    return [2 /*return*/, validationErrors];
                                case 4:
                                    if (!(this.allDiscounts.forPeriod == null ||
                                        range.start != this.allDiscounts.forPeriod.start ||
                                        range.end != this.allDiscounts.forPeriod.end)) return [3 /*break*/, 6];
                                    return [4 /*yield*/, api.res_discounts(this.quote.Listing.pid, range.start, range.end)];
                                case 5:
                                    res_discounts = _a.sent();
                                    this.allDiscounts.forPeriod = {
                                        start: range.start,
                                        end: range.end
                                    };
                                    this.allDiscounts.list = res_discounts;
                                    _a.label = 6;
                                case 6:
                                    // let oldDiscountCode = (quote.SelectedDiscount) ? quote.SelectedDiscount.code || '' : '';
                                    // if (this.selectedDiscount != null && this.selectedDiscount.code != '' && this.selectedDiscount.code != oldDiscountCode) {
                                    //   var discountDetails = await api.discount_details(
                                    //     this.quote.Listing.pid,
                                    //     range.start,
                                    //     range.end,
                                    //     this.selectedDiscount.code
                                    //   );
                                    //   this.selectedDiscount = discountDetails;
                                    // }
                                    quote.Arrive = range ? range.start : null;
                                    quote.Depart = range ? range.end : null;
                                    quote.Adults = this.adults;
                                    quote.Children = this.children;
                                    quote.NumPets = this.pets;
                                    quote.NumGuests = this.adults + this.children;
                                    quote.SelectedDiscount = this.selectedDiscount;
                                    d = new Date();
                                    d.setDate(d.getDate() + 31);
                                    showCFAR = (new Date(range.start) > d);
                                    if (quote.InsuranceCoverage === 'cfar' && !showCFAR) {
                                        quote.InsuranceCoverage = '';
                                    }
                                    else if (quote.InsuranceCoverage === 'ti' && showCFAR) {
                                        quote.InsuranceCoverage = '';
                                    }
                                    this.$emit("show-errors", null);
                                    this.$emit("quote-updated", quote);
                                    return [2 /*return*/];
                            }
                        });
                    }); }, 500);
                    return [2 /*return*/, null];
                });
            });
        }
    }
});
