import PageComponent from '@/lib/page-component';
import navigation from '@/components/navigation.vue';
import loadingIndicator from '@/components/loadingIndicator.vue';
import listingPreview from '@/components/listingPreview.vue';
import layoutFooter from '@/components/layoutFooter.vue';
import layoutHeader from '@/components/layoutHeader.vue';
import store from '@/store';
export default PageComponent.extend({
    components: { navigation: navigation, layoutFooter: layoutFooter, layoutHeader: layoutHeader, loadingIndicator: loadingIndicator, listingPreview: listingPreview },
    data: function () {
        return {
            phoneAdVisible: true
        };
    },
    computed: {
        is_mobile: function () {
            var toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
            ];
            // return toMatch.some((x) => navigator.userAgent.match(x));
            for (var _i = 0, toMatch_1 = toMatch; _i < toMatch_1.length; _i++) {
                var el = toMatch_1[_i];
                if (navigator.userAgent.match(el)) {
                    return true;
                }
            }
            return false;
        },
        isTestMode: function () {
            return store.state.isTestMode;
        },
        isWB: function () {
            var _a, _b;
            return (_b = (_a = this.$route.matched[1].props) === null || _a === void 0 ? void 0 : _a.default) === null || _b === void 0 ? void 0 : _b.isWB;
        },
    },
    metaInfo: function () {
        return store.getters.getMetaInfo;
    },
    mounted: function () {
        var event;
        var element = document;
        if (element.createEvent) {
            event = element.createEvent('HTMLEvents');
            event.initEvent('pageready', true, true);
        }
        else {
            event = element.createEventObject();
            event.eventType = 'pageready';
        }
        event.eventName = 'pageready';
        if (document.createEvent) {
            element.dispatchEvent(event);
        }
        else {
            element.fireEvent('on' + event.eventType, event);
        }
    }
});
