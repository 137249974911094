var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import * as quoteLib from '@/lib/quote';
import api from '@/api';
import store from '@/store';
export default Vue.extend({
    name: 'reservation-payment',
    props: ['quote', 'quotePrice'],
    data: function () {
        var thisYear = new Date().getFullYear();
        var result = {
            validation: {},
            checked: true,
            isCopied: false,
            dateFormat: {
                title: 'MMM YYYY',
                weekdays: 'W',
                navMonths: 'MMM',
                input: ['L', 'MM/DD/YYYY', 'MM/DD/YYYY'],
                dayPopover: 'L',
                data: ['L', 'MM/DD/YYYY', 'MM/DD/YYYY'] // For attribute dates
            },
            allCountries: [],
            expYears: Array.from({ length: 10 }, function (v, k) { return k + thisYear; }),
            expMonths: Array.from({ length: 12 }, function (v, k) { return k + 1; }),
            termsOfRentalsVisible: false,
            cancellationPolicyVisible: false,
            termsOfRentals: "Loading...",
            cancellationPolicy: "Loading...",
            refUpdatePriceLimit: 15000,
        };
        return result;
    },
    computed: {
        showCFAR: function () {
            var d = new Date();
            d.setDate(d.getDate() + 31);
            return (new Date(this.quotePrice.arrive) > d);
        },
        isRefundableBooking: function () {
            return this.quote.InsuranceCoverage === "rfbk";
        },
        isTestMode: function () {
            return store.state.isTestMode;
        }
    },
    methods: {
        loadLookups: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = this;
                            return [4 /*yield*/, api.countries()];
                        case 1:
                            _a.allCountries = (_b.sent());
                            return [2 /*return*/];
                    }
                });
            });
        },
        loadTerms: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = this;
                            return [4 /*yield*/, api.loadTermsOfRentals(this.quotePrice.ListingId, this.quotePrice.pubResId)];
                        case 1:
                            _a.termsOfRentals = (_b.sent());
                            return [2 /*return*/];
                    }
                });
            });
        },
        loadCancellationPolicy: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = this;
                            return [4 /*yield*/, api.loadCancellationPolicy(this.quotePrice.ListingId, this.quotePrice.pubResId)];
                        case 1:
                            _a.cancellationPolicy = (_b.sent());
                            return [2 /*return*/];
                    }
                });
            });
        },
        showTermsOfRental: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.loadTerms();
                    this.termsOfRentalsVisible = true;
                    return [2 /*return*/];
                });
            });
        },
        showCancellationPolicy: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.loadCancellationPolicy();
                    this.cancellationPolicyVisible = true;
                    return [2 /*return*/];
                });
            });
        },
        validate: function (event, displayList) {
            return __awaiter(this, void 0, Promise, function () {
                var v_result;
                return __generator(this, function (_a) {
                    try {
                        v_result = quoteLib.ValidateFormFields(this.$refs);
                        this.validation = v_result.validationErrors;
                        if (!this.quote.renter.agreeToCancelationPolicy) {
                            v_result.errors.push("You must agree to Cancelation Policy");
                            v_result.validationErrors.agreeToCancelationPolicy = true;
                        }
                        else {
                            v_result.validationErrors.agreeToCancelationPolicy = false;
                        }
                        if (!this.quote.renter.agreeToTermsOfRental) {
                            v_result.errors.push("You must agree to Terms of Rental");
                            v_result.validationErrors.agreeToTermsOfRental = true;
                        }
                        else {
                            v_result.validationErrors.agreeToTermsOfRental = false;
                        }
                        if (!this.quote.renter.agreeToRefundableBooking && this.isRefundableBooking) {
                            v_result.errors.push("You must agree to Refundable Booking");
                            v_result.validationErrors.agreeToRefundableBooking = true;
                        }
                        else {
                            v_result.validationErrors.agreeToRefundableBooking = false;
                        }
                        // if (!this.quote.renter.agreeToBackgroundCheck) {
                        //   v_result.errors.push("You must agree to a background check");
                        //   v_result.validationErrors.agreeToBackgroundCheck = true;
                        // }
                        if (displayList || v_result.errors.length == 0)
                            this.$emit('show-errors', v_result.errors);
                        return [2 /*return*/, v_result.errors];
                    }
                    catch (e) {
                        console.error(e);
                        return [2 /*return*/, null];
                    }
                    return [2 /*return*/];
                });
            });
        },
        copyRenterInfo: function () {
            if (!this.isCopied) {
                this.isCopied = true;
                this.quote.renter.billing_city = this.quote.renter.city;
                this.quote.renter.billing_address = this.quote.renter.address;
                this.quote.renter.billing_state = this.quote.renter.state;
                this.quote.renter.billing_zip = this.quote.renter.zip;
                this.quote.renter.billing_name = this.quote.renter.firstName + ' ' + this.quote.renter.lastName;
                this.quote.renter.billing_countryID = this.quote.renter.countryID;
                //this.$emit('quote-updated', this.quote)
            }
            else {
                this.isCopied = false;
                this.quote.renter.billing_city = '';
                this.quote.renter.billing_address = '';
                this.quote.renter.billing_state = '';
                this.quote.renter.billing_zip = '';
                this.quote.renter.billing_name = '';
                this.quote.renter.billing_countryID = '';
            }
        },
        setAgreeToTerms: function () {
            this.quote.renter.agreeToTermsOfRental = !this.quote.renter.agreeToTermsOfRental;
        },
        setAgreeToRefundableBooking: function () {
            this.quote.renter.agreeToRefundableBooking = !this.quote.renter.agreeToRefundableBooking;
        },
        setAgreeToCancelationPolicy: function () {
            this.quote.renter.agreeToCancelationPolicy = !this.quote.renter.agreeToCancelationPolicy;
        },
        setAgreeToBackgroundCheck: function () {
            this.quote.renter.agreeToBackgroundCheck = !this.quote.renter.agreeToBackgroundCheck;
        },
        setSubscribeToPromotions: function () {
            this.quote.renter.subscribeToPromotions = !this.quote.renter.subscribeToPromotions;
        },
        openInNewTab: function (event) {
            Object.assign(document.createElement('a'), { target: '_blank', href: event.target.href }).click();
        },
        goToInsuranceTab: function () {
            this.$emit('go-to-tab', 'step2');
        },
        isTooFarInAdvance: function () {
            var tooAdvancedDate = new Date();
            tooAdvancedDate = new Date(tooAdvancedDate.setMonth(tooAdvancedDate.getMonth() + 8));
            return this.quote.Depart > tooAdvancedDate;
        },
        onEnter: function () {
        },
        getCountryById: function (countryId) {
            return this.allCountries.find(function (e) { return e.id == countryId; });
        },
        formatDateNoDashes: function (d) {
            return "".concat(d.getFullYear(), "-").concat((d.getMonth() + 1), "-").concat(d.getDate());
        },
        formatDateMDY: function (d) {
            if (d)
                return "".concat((d.getMonth() + 1), "/").concat(d.getDate(), "/").concat(d.getFullYear());
            else
                return 'N/A';
        },
    },
    mounted: function () {
        this.loadLookups();
    }
});
