import Vue from 'vue';
import { aws_root } from '@/config';
export default Vue.extend({
    props: ['dataList', 'title', 'hasTopics'],
    data: function () {
        return {};
    },
    computed: {
        listingNum: function () {
            return this.$route.params.listingNum;
        },
        pubResId: function () {
            return this.$route.params.pubResId;
        }
    },
    methods: {
        getTopicImgUrl: function (item) {
            return "".concat(aws_root, "/WBImage/t-").concat(item.id, "/1.jpeg");
        },
        getCategoryImgUrl: function (item) {
            return "".concat(aws_root, "/WBImage/c-").concat(item.id, "/1.jpeg");
        }
    },
});
