import Vue from 'vue';
import * as quoteLib from '@/lib/quote';
import lib from '@/api';
import { isMobile } from '@/api';
import store from '@/store';
var refreshTimer = 0;
export default Vue.extend({
    name: 'reservation-quote',
    props: ['quotePrice', 'errors'],
    computed: {
        nights: function () {
            return quoteLib.datediff(new Date(this.quotePrice.arrive), new Date(this.quotePrice.depart));
        },
        isTestMode: function () {
            return store.state.isTestMode;
        }
    },
    data: function () {
        return {
            showDetails: !isMobile()
        };
    },
    mounted: function () {
    },
    methods: {
        formatPrice: function (n) {
            if (n)
                return '$' + (n.toFixed(2));
            else
                return '';
        },
        formatDate: function (d) {
            return lib.formatDate(d);
        },
        formatArrive: function (data) {
            var d = lib.parseDate(data);
            if (!d)
                return '';
            return "".concat(d.getMonth() + 1, "/").concat(d.getDate());
        }
    },
});
