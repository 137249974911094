// const apiRoot = 'http://office.puldin.eu/NomadnessWebAPI/api'
export var aws_root = 'https://s3.amazonaws.com/nmr-cdn';
export var apiRoot = "".concat(aws_root, "/api/");
export var imageRoot = "".concat(aws_root, "/img/");
export var siteName = "nomadness";
export var platform_apiRoot = 'https://apps.mammothrentalbyowner.com';
export var isTestMode = false;
export var GoogleSiteID = 'G-GXRPTQ6M2Z';
// export const GoogleTagManagerID = 'GTM-WKWS5LG';
export var showBlog = true;
export var facebook_api_version = 'v20.0';
export var facebook_api_pixel_id = '403150752816934';
export var facebook_apiRoot = "https://graph.facebook.com/".concat(facebook_api_version, "/").concat(facebook_api_pixel_id);
export var facebook_access_token = 'EAANC1ii4HkYBO1W7sIhzqWTx8qSMpPNMLzZBojvDvO8ZADQ2CLaXlarpy467ZBdhknvRXhKUFHuv5bZCACbCBrkACZAtXoYXNF6Mu3yNQLyphkZAlzw2vZCHOB3KtT8ZBkIEdNEteF4rmhqZC3lGzRMjY8NmEy1j4ZAZCXABMG2xDAWLneBk0qMTf38mYAvOjGBwsZC31QZDZD';
