import Vue from 'vue';
import propertyThumbnailImage from '@/components/listing/property-thumbnail.vue';
import lazyImage from '@/components/lazy-image.vue';
import store, { startLoadingProperty } from '@/store';
export default Vue.extend({
    name: 'properties',
    props: ['items', 'sm', 'propertyDes', 'isDiscountSearch'],
    components: { propertyThumbnailImage: propertyThumbnailImage, lazyImage: lazyImage },
    methods: {
        hasLastMinuteDiscount: function (property) {
            return property.discounts && property.discounts.some(function (d) { return d.isLastMinute && d.percent; });
        },
        getLastMinuteDiscountPercent: function (property) {
            var maxPercent = 0;
            property.discounts.forEach(function (discount) {
                var discountPercent = discount.percent;
                if (discount.isLastMinute && discountPercent && discountPercent > maxPercent) {
                    maxPercent = discountPercent;
                }
            });
            return maxPercent;
        },
        getIcon: function (amenity) {
            return amenity.toLowerCase().replace(' ', '').replace('near', '');
        },
        isOnSale: function (property) {
            return property.onSale != null;
        },
        startLoading: function (property) {
            startLoadingProperty(store, {
                id: property.id,
                title: property.title,
                url: property.mainImage(),
                isLoadingListing: true
            });
        }
        // print(availability: boolean[]) {
        //   let d = new Date( new Date().setHours(0,0,0,0) - 24*60*60*1000 )
        //   let result = ''
        //   availability.forEach( x => {
        //     result += lib.formatDate( d ) + ' : ' + x + '<br>'
        //     d.setDate(d.getDate() + 1)
        //   })
        //   return result
        // }
    }
});
