import Vue from 'vue';
import { preloadImage } from '@/api';
import { siteName, imageRoot } from '@/config';
import activityEventCalendar from './activity-event-calendar.vue';
import footnotes from '@/components/footnotes.vue';
export default Vue.extend({
    name: 'activity-list-item',
    props: ['activity'],
    components: { activityEventCalendar: activityEventCalendar, footnotes: footnotes },
    data: function () {
        return {
            modalImg: 'N/A',
            modalImgIndex: 0,
            eventImages: {},
            preloadCounter: 0,
            currentPage: 1,
            pageSize: 10,
            searchTerm: '',
            showSearch: false
        };
    },
    computed: {
        matchingActivities: function () {
            var _this = this;
            // @ts-ignore
            return this.activity.subPages.filter(function (activity) {
                return activity.title.toLowerCase().indexOf(_this.searchTerm.toLowerCase()) > -1;
            });
        },
        activitiesCurrentPage: function () {
            return this.matchingActivities.slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 0) * this.pageSize);
        },
        notMapOrDirection: function () {
            return this.activity && this.activity.title !== "Maps & Directions";
        },
    },
    methods: {
        toggleSearch: function () {
            var _this = this;
            this.showSearch = !this.showSearch;
            this.$nextTick(function () { return _this.$refs.searchTerm.focus(); });
        },
        paginate: function (page) {
            this.currentPage = page;
            if (typeof document !== 'undefined') {
                var pnl = document.getElementById("search-results-panel");
                pnl.scrollIntoView();
                var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
                if (viewportWidth > 800) {
                    window.scrollBy(0, -200);
                }
                else {
                    window.scrollBy(0, -80);
                }
            }
        },
        imageURL: function (pageId, pictureId) {
            var _this = this;
            if (!this.eventImages['img_' + pageId + '_' + pictureId]) {
                this.eventImages['img_' + pageId + '_' + pictureId] = "".concat(imageRoot).concat(siteName, "/").concat(pageId, "/t/").concat(pictureId, ".jpg");
                this.preloadCounter += 1;
                preloadImage("".concat(imageRoot).concat(siteName, "/").concat(pageId, "/xl/").concat(pictureId, ".jpg")).then(function (url) {
                    _this.eventImages['img_' + pageId + '_' + pictureId] = url;
                    _this.preloadCounter -= 1;
                });
            }
            return this.preloadCounter >= 0 ? this.eventImages['img_' + pageId + '_' + pictureId] : '';
        },
        shortenBody: function (html) {
            var doc = new DOMParser().parseFromString(html, 'text/html');
            var result = (doc.body.textContent || "").replace(/&nbsp;/gi, ' ').replace(' ', ' ').substr(0, 183) + "...";
            return result;
        },
        showModal: function (ndx) {
            // var img = this.activity.images[ndx]
            this.$modal.show('gallery-image');
            this.$data.modalImg = this.imageURL(this.activity.id, ndx);
            this.$data.modalImgIndex = ndx;
        },
        closeModal: function () {
            this.$modal.hide('gallery-image');
        },
        setModalImageIndex: function (ndx) {
            this.$data.modalImg = this.imageURL(this.activity.id, ndx);
            this.$data.modalImgIndex = ndx;
        }
    }
});
