import { dateParser } from '@/lib/tools';
import { siteName } from '@/config';
export var getSearchDescription = function (query, Arrive, Depart, LocationID) {
    var NumGuests = +query.guests;
    var Bedrooms = +query['bedrooms'];
    var complex = query['complex'];
    var property_type = +query['property_type'];
    var seasonal = query['seasonal_vacation'];
    if (seasonal != undefined) {
        seasonal = seasonal == '1';
    }
    var min_stay = +query['min_stay'];
    var pool = query['pool'] == 'on';
    var spa = query['spa'] == 'on';
    var wifi = query['wifi'] == 'on';
    var ac = query['ac'] == 'on';
    var pets = query['pets'] == 'on';
    var golf = query['golf'] == 'on';
    var gondola = query['gondola'] == 'on';
    var garage = query['garage'] == 'on';
    var IsEcoFriendly = query['IsEcoFriendly'] == 'on'; //must be on 
    var village = query['village'] == 'on';
    var fishing = query['fishing'] == 'on';
    var ski = query['ski'] == 'on';
    var searchType = query['search-type']; //"search-type": "discounts"
    var result = '';
    if (LocationID) {
        var locations = {
            1: 'Mammoth Lakes, CA USA',
            8: 'Steamboat Springs, CO USA',
            9: 'Crowley Lake CA, USA',
            10: 'June Lake CA, USA',
            12: 'Newport Beach CA, USA',
            15: 'Lake Tahoe CA, USA'
        };
        result += "Properties in ".concat(locations[LocationID], ", ");
    }
    if (NumGuests) {
        result += "minimum guests: ".concat(NumGuests, ", ");
    }
    if (Arrive && Depart) {
        result += "period between: ".concat(Arrive, " and ").concat(Depart, ", ");
    }
    if (Bedrooms) {
        result += "bedrooms: ".concat(Bedrooms, ", ");
    }
    if (complex) {
        result += "complex: ".concat(complex, ", ");
    }
    if (property_type) {
        var propertyTypes = { 1: 'Condo/Townhouse', 3: 'House', 4: 'Cabin', 5: 'Resort' };
        result += "property type: ".concat(propertyTypes[property_type], ", ");
    }
    if (seasonal != undefined) {
        result += "".concat(seasonal ? 'Seasonal' : 'Vacation', ", ");
    }
    if (min_stay) {
        var minStay = { 1: '1-2 nights', 3: '3-4 nights', 5: '5+ nights' };
        result += "stays: ".concat(minStay[min_stay], ", ");
    }
    if (pool) {
        result += "Pool, ";
    }
    if (spa) {
        result += "Spa, ";
    }
    if (wifi) {
        result += "WiFi, ";
    }
    if (ac) {
        result += "A/C, ";
    }
    if (pets) {
        result += "Pet Friendly, ";
    }
    if (golf) {
        result += "Near Golf, ";
    }
    if (gondola) {
        result += "Near Gondola, ";
    }
    if (garage) {
        result += "Private Garage, ";
    }
    if (IsEcoFriendly) {
        result += "Eco Friendly, ";
    }
    if (village) {
        result += "Near Village, ";
    }
    if (fishing) {
        result += "Near Fishing, ";
    }
    if (ski) {
        result += "Ski-In/Ski-Out, ";
    }
    if (searchType == "discounts") {
        result += "Last Minute Discount, ";
    }
    result = result.replace(/,\s*$/, '.');
    return result;
};
export var parseAvailability = function (query) {
    var checkIn = dateParser(query['check-in']);
    var checkOut = dateParser(query['check-out']);
    var selectedRange = { start: '', end: '' };
    if (checkIn || checkOut) {
        selectedRange = { start: checkIn.toLocaleDateString('en-US'), end: checkOut.toLocaleDateString('en-US') };
    }
    return selectedRange;
};
export var updateMeta = function (description) {
    var descEl = document.querySelector('head meta[name="description"]');
    if (descEl)
        descEl.setAttribute('content', description);
};
export var getDefaultPageLocation = function () {
    return siteName == 'steam' ? 8 : siteName == 'mrbo' ? 1 : 0;
};
export var getLdJson = function (pageLocation) {
    var getJson = function (locationId) {
        return {
            type: 'application/ld+json',
            json: {
                '@context': 'https://schema.org',
                '@type': 'LodgingBusiness',
                name: 'Nomadness Rentals',
                image: 'https://s3.amazonaws.com/nmr-cdn/img/static/nomadness_logo.png',
                '@id': 'https://www.nomadnessrentals.com/',
                url: 'https://www.nomadnessrentals.com/',
                telephone: '(888)472-5777',
                priceRange: '$ - $$$',
                address: {
                    '@type': 'PostalAddress',
                    streetAddress: locationId == 8 ? '3001 South Lincoln Ave' : '587 Old Mammoth Road #5',
                    addressLocality: locationId == 8 ? 'Steamboat Springs' : 'Mammoth Lakes',
                    addressRegion: 'CO',
                    postalCode: locationId == 8 ? '80487' : '93546',
                    addressCountry: 'US'
                },
                geo: {
                    '@type': 'GeoCoordinates',
                    latitude: locationId == 8 ? 40.4505032 : 37.6375968,
                    longitude: locationId == 8 ? -106.8210048 : -118.968221
                },
                sameAs: [
                    'https://www.facebook.com/nomadnessvacationrentals',
                    'https://twitter.com/nomadnessrental',
                    'https://www.instagram.com/nomadnessvacationrentals',
                    'https://www.youtube.com/user/mrboflix'
                ]
            }
        };
    };
    var metainfoScript = [];
    if (pageLocation == 1 || pageLocation == 8) {
        metainfoScript.push(getJson(pageLocation));
    }
    else {
        metainfoScript.push(getJson(1));
        metainfoScript.push(getJson(8));
    }
    return metainfoScript;
};
