import Vue from 'vue';
import store from '@/store';
export default Vue.extend({
    name: 'listingPreview',
    data: function () {
        return {
            url: store.state.loadingImgData.url,
            title: store.state.loadingImgData.title,
            layoutCss: ''
        };
    },
    methods: {
        showLayoutBackground: function () {
            this.layoutCss = 'show-layout';
        }
    },
    mounted: function () {
        var _this = this;
        setTimeout(function () { return _this.showLayoutBackground(); }, 1500);
    }
});
