var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import searchBar from '@/components/search-bar.vue';
import featuredProperties from './featured-properties.vue';
import properties from './properties.vue';
import PageComponent from '@/lib/page-component';
import propertyThumbnailImage from '@/components/listing/property-thumbnail.vue';
import lazyImage from '@/components/lazy-image.vue';
// import imgoing from "../listing/imgoing.vue"
import store, { loadLocations, loadListings, loadDiscounts, loadDiscount, setMetaInfo } from '@/store';
import * as seo from '@/router/seo';
import { dateParser, parseNumber } from '@/lib/tools';
function routeUpdate(to, from, next) {
    return __awaiter(this, void 0, Promise, function () {
        var query, locationSlug, locationID, guests, page, bedrooms, price, searchType, qIn, qOut, checkIn, checkOut, complex, sortby, property_type, seasonal, min_stay, pool, spa, wifi, ac, pets, golf, gondola, garage, IsEcoFriendly, village, fishing, ski, discountId, isShortTerm, isLongTerm, findDiscountedListings, fromInactiveListing, featured, newListing, allLocations, location, findListings, listings, hasLastMinuteDiscounts, discount, discountListings_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    query = to.query;
                    locationSlug = to.params.locationSlug;
                    locationID = query.location;
                    if (locationSlug === 'all')
                        locationID = "0";
                    guests = query.guests;
                    page = query.page || 1;
                    bedrooms = query.bedrooms;
                    if (bedrooms == '')
                        bedrooms = undefined;
                    price = query.price;
                    if (price == '')
                        price = undefined;
                    searchType = query["search-type"];
                    if (!searchType)
                        searchType = 'all';
                    qIn = query['check-in'];
                    qOut = query['check-out'];
                    checkIn = (qIn && qIn.length) ? dateParser(qIn) : null;
                    checkOut = (qOut && qOut.length) ? dateParser(qOut) : null;
                    complex = query['complex'];
                    sortby = query['sortby'];
                    property_type = query['property_type'];
                    seasonal = query['seasonal_vacation'];
                    min_stay = query['min_stay'];
                    pool = query['pool'] == "on" ? true : undefined;
                    spa = query['spa'] == "on" ? true : undefined;
                    wifi = query['wifi'] == "on" ? true : undefined;
                    ac = query['ac'] == "on" ? true : undefined;
                    pets = query['pets'] == "on" ? true : undefined;
                    golf = query['golf'] == "on" ? true : undefined;
                    gondola = query['gondola'] == "on" ? true : undefined;
                    garage = query['garage'] == "on" ? true : undefined;
                    IsEcoFriendly = query['IsEcoFriendly'] == "on" ? true : undefined;
                    village = query['village'] == "on" ? true : undefined;
                    fishing = query['fishing'] == "on" ? true : undefined;
                    ski = query['ski'] == "on" ? true : undefined;
                    discountId = query['discount-id'];
                    isShortTerm = to.params.isLongTerm !== 'yes' ? true : undefined;
                    isLongTerm = to.params.isLongTerm === 'yes' ? true : undefined;
                    findDiscountedListings = searchType === 'discounts' ? true : undefined;
                    fromInactiveListing = to.params.fromInactiveListing === 'yes';
                    featured = query['featured'] == "on" ? true : undefined;
                    newListing = query['newListing'] == "on" ? true : undefined;
                    return [4 /*yield*/, loadLocations(store)];
                case 1:
                    allLocations = (_a.sent()).subPages;
                    location = null;
                    if (!locationID) {
                        location = allLocations.find(function (l) { return l.slug == locationSlug; });
                        locationID = location ? location.id.toString() : "0";
                    }
                    else {
                        location = allLocations.find(function (l) { return l.id == +locationID; });
                    }
                    return [4 /*yield*/, loadListings(store)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, loadDiscounts(store)];
                case 3:
                    _a.sent();
                    findListings = function (isDiscountsSearch) {
                        return store.getters.listings({
                            LocationID: +locationID,
                            NumGuests: +guests,
                            Arrive: checkIn,
                            Depart: checkOut,
                            Bedrooms: bedrooms,
                            Price: price,
                            complex: complex,
                            property_type: property_type,
                            seasonal: seasonal,
                            isShortTerm: isShortTerm,
                            isLongTerm: isLongTerm,
                            isDiscountsSearch: isDiscountsSearch,
                            featured: featured,
                            newListing: newListing,
                            min_stay: min_stay,
                            pool: pool,
                            spa: spa,
                            wifi: wifi,
                            ac: ac,
                            pets: pets,
                            golf: golf,
                            gondola: gondola,
                            garage: garage,
                            IsEcoFriendly: IsEcoFriendly,
                            village: village,
                            fishing: fishing,
                            ski: ski,
                            sortby: sortby
                        });
                    };
                    listings = findListings(findDiscountedListings);
                    hasLastMinuteDiscounts = findListings(true).length > 0;
                    if (!hasLastMinuteDiscounts) {
                        listings = findListings(undefined);
                    }
                    if (!discountId) return [3 /*break*/, 5];
                    return [4 /*yield*/, loadDiscount(store, parseNumber(discountId))];
                case 4:
                    discount = _a.sent();
                    discountListings_1 = new Set(discount.listingIDs.map(function (x) { return parseInt(x, 10); }));
                    listings = listings.filter(function (x) { return discountListings_1.has(x.id); });
                    _a.label = 5;
                case 5:
                    next(function (vm) {
                        var $vm = vm;
                        var $data = vm.$data || {};
                        $data.locationID = locationID;
                        $data.guests = guests;
                        $data.listings = listings;
                        $data.checkIn = checkIn;
                        $data.checkOut = checkOut;
                        $data.searchType = searchType;
                        $data.currentPage = page;
                        $data.hasListings = listings.length != 0;
                        $data.shortFootnotes = location && location.text_section_1;
                        $data.longFootnotes = location && location.text_section_2;
                        $data.meta_title = location && location.meta_title;
                        $data.meta_keywords = location && location.meta_keywords;
                        $data.meta_description = location && location.meta_description;
                        $data.disableLastMinDiscounts = !hasLastMinuteDiscounts;
                        $data.fromInactiveListing = fromInactiveListing;
                        setTimeout(function () {
                            $vm.$refs.pagination.setPage(page);
                        }, 50);
                        var dates = seo.parseAvailability(query);
                        $data.searchDetails = seo.getSearchDescription(query, dates.start, dates.end, +locationID);
                    });
                    return [2 /*return*/];
            }
        });
    });
}
var prevExpandFooter = false;
export default PageComponent.extend({
    name: 'search-results',
    components: { featuredProperties: featuredProperties, searchBar: searchBar, properties: properties, propertyThumbnailImage: propertyThumbnailImage, lazyImage: lazyImage },
    beforeRouteEnter: function (to, from, next) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                routeUpdate(to, from, next);
                return [2 /*return*/];
            });
        });
    },
    data: function () {
        var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var paginationOptions = {
            chunk: viewportWidth > 767 ? 15 : 5
        };
        return {
            infoWindowPos: null,
            infoWinOpen: false,
            infoOptions: { maxWidth: 200, pixelOffset: { width: 0, height: -35 } },
            markerIndex: 0,
            currentPage: 1,
            pageSize: 12,
            locationID: '',
            guests: '',
            checkIn: '',
            checkOut: '',
            searchType: '',
            listings: [],
            searchDetails: '',
            hasListings: true,
            paginationOptions: paginationOptions,
            shortFootnotes: '',
            longFootnotes: '',
            expandFooter: false,
            read_more: 'Read More',
            disableLastMinDiscounts: false,
            fromInactiveListing: false,
            lastPage: -1,
            meta_title: '',
            meta_keywords: '',
            meta_description: ''
        };
    },
    computed: {
        listingsCurrentPage: function () {
            return this.listings.slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 0) * this.pageSize);
        },
        featuredListings: function () {
            return this.listings.filter(function (listing) {
                return listing.options.featured;
            }).sort(function (a, b) { return Math.random() - 0.5; });
        }
    },
    methods: {
        toggleReadMore: function () {
            this.expandFooter = !this.expandFooter;
            this.read_more = this.expandFooter ? 'Read Less' : 'Read More';
        },
        toggleInfoWindow: function (listing, index) {
            this.infoWindowPos = listing.position;
            //check if its the same listing that was selected if yes toggle
            if (this.markerIndex == index) {
                this.infoWinOpen = !this.infoWinOpen;
            }
            //if different listing set infowindow to open and reset current listing index
            else {
                this.infoWinOpen = true;
                this.markerIndex = index;
            }
        },
        isDate: function (date) {
            return date instanceof Date && !isNaN(date.valueOf());
        },
        paginate: function (page) {
            var _this = this;
            this.currentPage = page;
            var routerName = undefined;
            if (this.isDate(this.checkIn) && this.isDate(this.checkOut)) {
                //@ts-ignore
                var diffTime = Math.abs(this.checkOut - this.checkIn);
                var numberOfDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                routerName = numberOfDays > 30 ? "long-term-rentals-search-by-location" : "rentals-search-by-location";
            }
            else {
                routerName = this.$route.name.includes("term") ? "long-term-rentals-search-by-location" : "rentals-search-by-location";
            }
            if (page !== 1 || this.$route.query.page) {
                this.$router.push({
                    name: routerName,
                    query: __assign(__assign({}, this.$route.query), { page: page !== 1 ? "".concat(page) : undefined }),
                    params: __assign(__assign({}, this.$route.params), { fromInactiveListing: "no" }),
                });
            }
            if (this.lastPage === page) {
                this.fromInactiveListing = false;
            }
            if (typeof document !== 'undefined') {
                setTimeout(function () {
                    var pnl = document.getElementById("search-results-panel");
                    pnl.scrollIntoView();
                    var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
                    if (viewportWidth > 800 && !_this.fromInactiveListing) {
                        window.scrollBy(0, -200);
                    }
                    else if (!_this.fromInactiveListing) {
                        window.scrollBy(0, -80);
                    }
                }, 50);
            }
            this.lastPage = page;
        },
        updateHeader: function () {
            return __awaiter(this, void 0, void 0, function () {
                var location, isLongTerm, rentalsText, locationName, middleText, defaultListingsQuery, metaInfoData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, loadLocations(store)];
                        case 1:
                            _a.sent();
                            location = store.getters.location(+this.locationID);
                            isLongTerm = this.$route.name.includes("term");
                            rentalsText = "";
                            if (location) {
                                locationName = location.slug.charAt(0).toUpperCase() + location.slug.slice(1);
                                rentalsText = locationName + (isLongTerm ? " Long Term" : "");
                            }
                            else {
                                rentalsText = isLongTerm ? "Long Term" : "Term";
                            }
                            if (this.searchType === 'discounts') {
                                middleText = location ? " ".concat(rentalsText) : "";
                                this.setTitle("Last Minute Discounted".concat(middleText, " Properties"));
                            }
                            else {
                                this.setTitle("".concat(rentalsText, " Properties"));
                            }
                            this.setLastSearch({
                                start: this.checkIn,
                                end: this.checkOut
                            });
                            defaultListingsQuery = {
                                guests: 2,
                                bedrooms: '',
                                price: '',
                                "check-in": null,
                                "check-out": null,
                                searchType: this.searchType || "all",
                            };
                            this.setBackgroundLink('');
                            this.setBreadcrumbs([
                                { label: 'Home', route: { name: 'home' } },
                                { label: 'Properties' },
                                { label: rentalsText,
                                    route: {
                                        name: this.$route.name,
                                        params: this.$route.params,
                                        query: defaultListingsQuery
                                    },
                                },
                                { label: 'Rentals' },
                            ]);
                            if (!location) {
                                return [2 /*return*/];
                            }
                            metaInfoData = {
                                title: this.$data.meta_title,
                                meta: [{
                                        vmid: 'description',
                                        name: 'description',
                                        content: this.$data.meta_description
                                    }, {
                                        vmid: 'keywords',
                                        name: 'keywords',
                                        content: this.$data.meta_keywords
                                    }],
                                script: seo.getLdJson(+this.locationID)
                            };
                            setMetaInfo(store, metaInfoData);
                            return [2 /*return*/];
                    }
                });
            });
        },
        onRouteChanged: function (to, from) {
            var _this = this;
            routeUpdate(to, from, function (callback) {
                callback(_this);
                _this.updateHeader();
            });
        }
    },
    watch: {
        'locationID': 'updateHeader',
        '$route': 'onRouteChanged'
    },
    mounted: function () {
        return __awaiter(this, void 0, void 0, function () {
            var query, dates;
            return __generator(this, function (_a) {
                this.updateHeader();
                query = this.$route.query;
                if (Object.keys(query).length) {
                    dates = seo.parseAvailability(query);
                    seo.updateMeta(seo.getSearchDescription(query, dates.start, dates.end, +this.locationID));
                }
                else {
                    seo.updateMeta('Mammoth Rental By Owner offers quality Mammoth vacation lodging rentals for the perfect vacation getaway.');
                }
                return [2 /*return*/];
            });
        });
    },
});
