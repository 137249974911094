import Vue from 'vue';
import VueRouter from 'vue-router';
import app from '@/app.vue';
// const root = () => import( '@/components/home/index.vue' )
// const search = () => import( '@/components/search/index.vue' )
// const newlistings = () => import( '@/components/search/new-properties.vue' )
// const listing = () => import( '@/components/listing/index.vue' )
// const location = () => import( '@/components/location/index.vue' )
// const locations = () => import( '@/components/location/list.vue' )
// const locationActivity = () => import( '@/components/location/activity.vue' )
// const discount = () => import( '@/components/discount/index.vue' )
// const discounts = () => import( '@/components/discount/list.vue' )
// const about = () => import( '@/components/about/index.vue' )
// const contact = () => import( '@/components/home/contact.vue' )
// const listproperty = () => import( '@/components/listproperty/index.vue' )
import root from '@/components/home/index.vue';
import search from '@/components/search/index.vue';
import newlistings from '@/components/search/new-properties.vue';
import listing from '@/components/listing/index.vue';
import calendar from '@/components/listing/calendar.vue';
import location from '@/components/location/index.vue';
import locations from '@/components/location/list.vue';
import locationActivity from '@/components/location/activity.vue';
import discount from '@/components/discount/index.vue';
import discounts from '@/components/discount/list.vue';
import about from '@/components/about/index.vue';
import contact from '@/components/home/contact.vue';
import conciergeServiceHome from '@/components/concierge-services/index.vue';
import conciergeServiceType from '@/components/concierge-services/type.vue';
import conciergeServiceDetails from '@/components/concierge-services/details.vue';
import listpropertynew from '@/components/listproperty/index-new.vue';
import career from '@/components/career/index.vue';
import careerDetails from '@/components/career/details.vue';
import careerJobs from '@/components/career/jobs.vue';
import renterFAQ from '@/components/renter-faq/index.vue';
import privacyPolicy from '@/components/home/privacy.vue';
import blog from '@/components/blog/list.vue';
import blogPost from '@/components/blog/index.vue';
import pageNotFound from '@/components/404/index.vue';
import wbCategoriesView from '@/components/welcome-book/CategoriesView.vue';
import wbCategoryView from '@/components/welcome-book/CategoryView.vue';
import wbTopicView from '@/components/welcome-book/TopicView.vue';
import wbMapView from '@/components/welcome-book/MapView.vue';
import wbHomeView from '@/components/welcome-book/HomeView.vue';
// import teamMember from '@/components/about/team-member.vue'
export default function () {
    Vue.use(VueRouter);
    var router = new VueRouter({
        mode: 'history',
        scrollBehavior: function (to, from, savedPosition) {
            return { x: 0, y: 0 };
        },
        routes: [
            {
                path: '/calendar/:id',
                name: 'listing-calendar',
                component: calendar,
                props: true
            },
            {
                path: '/',
                component: app,
                children: [
                    { path: '/', name: 'home', component: root },
                    { path: '/location/:slug', name: 'location', component: location },
                    { path: '/locations', name: 'locations', component: locations },
                    {
                        path: '/long-term-rentals-search/:locationSlug',
                        name: 'long-term-rentals-search-by-location',
                        component: search,
                        props: true,
                        beforeEnter: function (to, from, next) {
                            to.params.isLongTerm = 'yes';
                            next();
                        }
                    },
                    //{
                    //  path: '/long-term-rentals-search',
                    //  name: 'long-term-rentals-search',
                    //  component: search,
                    //  props: true,
                    //  beforeEnter: (to, from, next) => {
                    //    to.params.isLongTerm = 'yes';
                    //    next();
                    //  }
                    //},
                    {
                        path: '/rentals-search/:locationSlug',
                        name: 'rentals-search-by-location',
                        component: search,
                        props: true,
                        beforeEnter: function (to, from, next) {
                            to.params.isLongTerm = 'no';
                            next();
                        }
                    },
                    //{
                    //  path: '/rentals-search',
                    //  name: 'rentals-search',
                    //  component: search,
                    //  props: true
                    //},
                    {
                        path: '/properties-new',
                        name: 'properties-new',
                        component: newlistings,
                        props: true
                    },
                    {
                        path: '/properties-rentals/:id/:slug',
                        name: 'listing',
                        component: listing,
                        props: true
                    },
                    {
                        path: '/discounts/:id',
                        name: 'discount',
                        component: discount,
                        props: true
                    },
                    {
                        path: '/discounts',
                        name: 'discounts',
                        component: discounts,
                        props: true
                    },
                    {
                        path: '/activities/:location/:activity?/:slug?',
                        name: 'Location Activity',
                        component: locationActivity
                    },
                    { path: '/about-us', name: 'About Us', component: about },
                    { path: '/contact', name: 'Contact Us', component: contact },
                    {
                        path: '/list-your-property',
                        name: 'list-your-property',
                        props: { headerCSS: 'XL-header-image' },
                        component: listpropertynew
                    },
                    {
                        path: '/concierge-services/:slug1/:slug2',
                        name: 'concierge-service-details',
                        component: conciergeServiceDetails,
                        props: { headerCSS: 'L-header-image' }
                    },
                    {
                        path: '/concierge-services/:slug1',
                        name: 'concierge-service-type',
                        component: conciergeServiceType,
                        props: { headerCSS: 'L-header-image' }
                    },
                    {
                        path: '/concierge-services',
                        name: 'concierge-services',
                        component: conciergeServiceHome,
                        props: { headerCSS: 'L-header-image' }
                    },
                    {
                        path: '/welcome-book/:listingNum/categories/res/:pubResId',
                        name: 'wb-categories-res',
                        component: wbCategoriesView,
                        props: { isWB: true },
                    },
                    {
                        path: '/welcome-book/:listingNum/categories',
                        name: 'wb-categories',
                        component: wbCategoriesView,
                        props: { isWB: true },
                    },
                    {
                        path: '/welcome-book/:listingNum/categories/:categorySlug/res/:pubResId',
                        name: 'wb-category-res',
                        component: wbCategoryView,
                        props: { isWB: true },
                    },
                    {
                        path: '/welcome-book/:listingNum/categories/:categorySlug',
                        name: 'wb-category',
                        component: wbCategoryView,
                        props: { isWB: true },
                    },
                    {
                        path: '/welcome-book/:listingNum/categories/:categorySlug/:topicSlug/res/:pubResId',
                        name: 'wb-topic-res',
                        component: wbTopicView,
                        props: { isWB: true },
                    },
                    {
                        path: '/welcome-book/:listingNum/categories/:categorySlug/:topicSlug',
                        name: 'wb-topic',
                        component: wbTopicView,
                        props: { isWB: true },
                    },
                    {
                        path: '/welcome-book/:listingNum/map/res/:pubResId',
                        name: 'wb-map-res',
                        component: wbMapView,
                        props: { isWB: true },
                    },
                    {
                        path: '/welcome-book/:listingNum/map',
                        name: 'wb-map',
                        component: wbMapView,
                        props: { isWB: true },
                    },
                    {
                        path: '/welcome-book/:listingNum/res/:pubResId',
                        name: 'wb-home-res',
                        component: wbHomeView,
                        props: { isWB: true },
                    },
                    {
                        path: '/welcome-book/:listingNum',
                        name: 'wb-home',
                        component: wbHomeView,
                        props: { isWB: true },
                    },
                    { path: '/career', name: 'Career', component: career },
                    {
                        path: '/career-details',
                        name: 'CareerDetails',
                        component: careerDetails
                    },
                    { path: '/jobs', name: 'CareerJobs', component: careerJobs },
                    { path: '/renter-faq', name: 'renterFAQ`', component: renterFAQ },
                    {
                        path: '/privacy-policy',
                        name: 'privacyPolicy',
                        component: privacyPolicy
                    },
                    {
                        path: '/blog',
                        name: 'Blog',
                        component: blog
                    },
                    {
                        path: '/blog-post/:id/:slug',
                        name: 'BlogPost',
                        component: blogPost
                    }
                ]
            },
            { path: '*', beforeEnter: function (to, from, next) { next('/404'); } },
            {
                path: '/404',
                component: app,
                children: [
                    {
                        path: '/404',
                        name: 'pageNotFound',
                        component: pageNotFound
                    }
                ]
            }
        ]
    });
    return router;
}
